import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';
export const inputBase = css`
  height: 60px;
  padding: 0 19px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
  color: #000;
  box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
  border: 1px solid lightblack;
  outline: none;
  width: 100%;
  transition: all 0.15s;
  -moz-appearance: none;
  -webkit-appearance: none;
  ${mediaDown('md')`
    font-size: 14px;
    line-height: 14px;
    padding: 0 15px;
    height: 45px;
  `}
  &:focus {
    border-color: ${(props) => props.primaryColor};
  }
`;

const Input = styled.input`
  ${inputBase};
`;

const Textarea = styled.textarea`
  ${inputBase};
  padding-top: 19px;
  padding-bottom: 19px;
  height: auto;
  resize: none;
  display: block;
  ${mediaDown('md')`
    padding-top: 15px;
    padding-bottom: 15px;
  `}
`;

const errorCss = css`
  ${Textarea},
  ${Input} {
    border-color: #f35b69;
    border-bottom-left-radius: 0;
  }
`;

const TextField = styled.div`
  ${(props) => props.error && errorCss}
`;

const Styled = {
  TextField,
  Input,
  Textarea,
};

export default Styled;
