class SessionStorage {
  static storageName = 'session';

  static storage = sessionStorage;

  static defaultUser = {
    Id: null,
    Username: null,
    FirstName: null,
    LastName: null,
    Email: null,
    LastLoginDate: null,
    LastIpAddress: null,
    Active: null,
    IsLoggedIn: null,
  };

  static initialState = {
    token: null,
    refreshToken: null,
    user: SessionStorage.defaultUser,
  };

  static getContent() {
    try {
      const stored = SessionStorage.storage.getItem(SessionStorage.storageName);
      if (stored) {
        return JSON.parse(stored);
      }
      return SessionStorage.initialState;
    } catch {
      return SessionStorage.initialState;
    }
  }

  static setContent(data) {
    const stored = SessionStorage.getContent();
    const dataToStore = { ...stored, ...data };

    SessionStorage.storage.setItem(
      SessionStorage.storageName,
      JSON.stringify(dataToStore),
    );
  }
}

export default SessionStorage;
