import _, { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Styled from './Hero.styles';
import styled from 'styled-components';

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the background color and opacity as per your design */
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Hero = ({ options, selectedOption }) => {
  const history = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  //   console.log(options);
  const [hide, setHide] = useState(false);
  const [oldOptions, setOldOptions] = useState([]);

  useEffect(() => {
    setOldOptions(options);
    if (oldOptions !== options) {
      setHide(false);
    }
  }, [options]);

  return (
    <>
      <StyledBackdrop
        show={options && options.length > 0 && !hide}
        onClick={() => setHide(true)}
      />
      <Styled.Hero
        style={{
          minWidth: options.length > 0 && !hide ? '250px' : '0px',
        }}
      >
        <Styled.ContentContainer>
          {/* <Styled.Title>Test</Styled.Title> */}
          <Styled.Autocomplete>
            <Styled.InputHolder hasResults={options && options.length > 0}>
              {/* <SearchIcon /> */}
              {/* <Styled.Input
                type="text"
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                placeholder={t('landing.hero.inputText')}
              ></Styled.Input> */}
              <Styled.Loader isLoading={false}>
                <Loader size={22} color="#c5c5c5" inline />
              </Styled.Loader>
            </Styled.InputHolder>

            {options && options.length > 0 && !hide && (
              <Styled.Results>
                <Scrollbars>
                  <div style={{ padding: '12px 0' }}>
                    <div>
                      {options?.map((v, i) => (
                        <Styled.ResultsGroupItem
                          key={i}
                          onClick={() => {
                            selectedOption(v.Value);
                            setHide(true);
                          }}
                        >
                          {v.Label}
                        </Styled.ResultsGroupItem>
                      ))}
                    </div>
                  </div>
                </Scrollbars>
              </Styled.Results>
            )}
          </Styled.Autocomplete>
        </Styled.ContentContainer>
      </Styled.Hero>
    </>
  );
};

export default Hero;
