import React, { useEffect } from 'react';
import { useState } from 'react';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';

const PriceCalculator = () => {
    const [days, setDays] = useState('');
    const [enteredKilometers, setEnteredKilometers] = useState(0);
    const [additionalKilometers, setAdditionalKilometers] = useState(0);
    const [calculatedDayPrice, setCalculatedDayPrice] = useState(45);
    const [userDefinedDayPrice, setUserDefinedDayPrice] = useState(false);
    const [selectedOption, setSelectedOption] = useState('LT');
    const [isChecked, setChecked] = useState(false);
    const [wasEUSelected, setWasEUSelected] = useState(false);

    const [rentPrice, setRentPrice] = useState(0);
    const [dailyPrice, setDailyPrice] = useState(0);
    const [additionalKmPrice, setAdditionalKmPrice] = useState(0);
    const [rentPriceWithPvm, setRentPriceWithPvm] = useState(0);

    const handleDaysChange = (e) => {
        const enteredDays = parseInt(e.target.value, 10) || '';
        setDays(enteredDays);

        // Automatically update enteredKilometers when day count changes
        const defaultKilometers = enteredDays ? enteredDays * 450 : '';
        setEnteredKilometers(defaultKilometers);
    };

    const handleKilometersChange = (e) => {
        const enteredValue = parseInt(e.target.value, 10) || 0;
        setEnteredKilometers(enteredValue);
    };

    const handleDayPriceChange = (e) => {
        const enteredDayPrice = parseInt(e.target.value, 10) || 0;
        setUserDefinedDayPrice(true);
        setCalculatedDayPrice(enteredDayPrice);
    };

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;
        const isEUSelected = selectedValue === 'EU';
        setSelectedOption(selectedValue);

        // Update price based on selected radio button
        if (isEUSelected) {
            setCalculatedDayPrice((prev) => prev + 5);
            setWasEUSelected(true);
        } else if (wasEUSelected) {
            setCalculatedDayPrice((prev) => prev - 5);
            setWasEUSelected(false);
        }
    };

    const handleCheckboxChange = () => {
        setChecked((prev) => !prev);
        console.log(isChecked)
        // Update price based on checkbox state
        setCalculatedDayPrice((prev) => (!isChecked ? prev + 5 : prev - 5));
    };

    useEffect(() => {
        const kilometers = days ? days * 450 : 4500;
        const additional = Math.max(enteredKilometers - kilometers, 0);
        setAdditionalKilometers(additional);

        if (!userDefinedDayPrice) {
            let dayPrice = days
                ? days <= 5
                    ? 55
                    : days <= 15
                        ? 50
                        : 45
                : 45;

            // Adjust day price based on selected option and Hook checkbox
            if (selectedOption === 'EU') {
                dayPrice += 5;
            }

            if (isChecked) {
                dayPrice += 5;
            }

            setCalculatedDayPrice(dayPrice);
        }

        setRentPrice((days * calculatedDayPrice) + (additionalKilometers * 0.12));
        setDailyPrice((days * calculatedDayPrice));
        setAdditionalKmPrice((additionalKilometers * 0.12));

        setRentPriceWithPvm(((days * calculatedDayPrice) + (additionalKilometers * 0.12)) * 1.21);


    }, [days, enteredKilometers, userDefinedDayPrice, selectedOption, isChecked, wasEUSelected, calculatedDayPrice, additionalKilometers]);


    return (
        <Styled.ContentWrapper>
            <Sidebar></Sidebar>
            <div style={{ marginTop: '45px', marginLeft: '45px' }}>

                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <label style={{ marginBottom: '10px' }}>
                        <input
                            type="radio"
                            value="LT"
                            checked={selectedOption === 'LT'}
                            onChange={handleRadioChange}
                        />
                        LT
                    </label>

                    <label style={{ marginBottom: '10px', marginLeft: '25px' }}>
                        <input
                            type="radio"
                            value="EU"
                            checked={selectedOption === 'EU'}
                            onChange={handleRadioChange}
                        />
                        Eu
                    </label>

                    <label style={{ marginBottom: '10px', marginLeft: '25px' }}>
                        <input
                            type="checkbox"
                            value="hook"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        Kablys
                    </label>
                </div>

                <div>
                    <label style={{ width: '150px' }}>Dienos kaina</label>
                    <input
                        type="number"
                        value={calculatedDayPrice}
                        onChange={handleDayPriceChange}
                    />
                </div>

                <div style={{ marginTop: '10px' }}>
                    <label style={{ width: '150px' }}>
                        Parų skaičius:
                    </label>
                    <input
                        type="number"
                        value={days}
                        onChange={handleDaysChange}
                    />

                    <div style={{ marginTop: '10px' }}>
                        <label style={{ width: '150px' }}>Kilometrazas</label>
                        <input
                            type="number"
                            value={enteredKilometers}
                            onChange={handleKilometersChange}
                        />
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <label style={{ width: '150px' }}>
                            Papildomi kilometrai:

                        </label>
                        <input
                            type="number"
                            value={additionalKilometers}
                            readOnly
                        />
                    </div>

                    <div style={{ marginTop: '35px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>   <p style={{ width: '175px' }}>Nuomos kaina:</p> <p> {rentPrice}</p></div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>   <p style={{ width: '175px' }}>Nuomos kaina su PVM:</p> <p> {rentPriceWithPvm.toFixed(2)}</p></div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>   <p style={{ width: '175px' }}>Kaina už paras:</p> <p> {dailyPrice}</p></div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>   <p style={{ width: '175px' }}>Papildomi KM:</p> <p> {additionalKmPrice}</p></div>
                    </div>
                </div>
            </div>
        </Styled.ContentWrapper>
    );
};
export default PriceCalculator;
