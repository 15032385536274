import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Slide } from 'react-slideshow-image';
import { Element } from 'react-scroll';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { Link } from 'react-scroll';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useForm } from 'react-cool-form';
import './formStyles.scss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import useFetch from 'use-http';
import { confirmAlert } from 'react-confirm-alert'; // Import

const cards1 = [
  {
    id: 1,
    name: 'Mercedes-benz',
    nr: 'MOG 474',
    year: '2020m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,3m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
      'Galinio vaizdo kamera',
      'Comfort sėdynė',
    ],
    images: [
      { src: './MOG474/1.jpg' },
      { src: './MOG474/2.jpg' },
      { src: './MOG474/3.jpg' },
    ],
    show: false,
  },
  {
    id: 2,
    name: 'Mercedes-benz',
    nr: 'MEA 737',
    year: '2016m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,7m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
      'Sustriprinta pakaba',
    ],
    images: [
      { src: './MEA737/1.jpg' },
      { src: './MEA737/2.jpg' },
      { src: './MEA737/3.jpg' },
    ],
    show: false,
  },
  {
    id: 3,
    name: 'Mercedes-benz',
    nr: 'MHD 135',
    year: '2018m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,3m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
      'Galinio vaizdo kamera',
      'Amortizuojanti sėdynė',
      'Sustriprinta pakaba',
    ],
    images: [
      { src: './MHD135/1.jpg' },
      { src: './MHD135/2.jpg' },
      { src: './MHD135/3.jpg' },
    ],
    show: false,
  },

];

const cards2 = [
  {
    id: 5,
    name: 'Mercedes-benz',
    nr: 'MJV 272',
    year: '2017m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,3m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
      'Kablys',
      'Amortizuojanti sėdynė',
      'Sustriprinta pakaba',
    ],
    images: [
      { src: './MJV272/1.jpg' },
      { src: './MJV272/2.jpg' },
      { src: './MJV272/3.jpg' },
    ],
    show: false,
  },
  {
    id: 6,
    name: 'Mercedes-benz',
    nr: 'MIJ 070',
    year: '2015m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,3m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
      'Kablys',
      'Amortizuojanti sėdynė',
      'Sustriprinta pakaba',
    ],
    images: [
      { src: './MIJ070/1.jpg' },
      { src: './MIJ070/2.jpg' },
      { src: './MIJ070/3.jpg' },
    ],
    show: false,
  },
  {
    id: 7,
    name: 'Mercedes-benz',
    nr: 'MJV 373',
    year: '2022m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,3m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Kablys',
      'Parkavimo jutikliai',
      'Galinio vaizdo kamera',
      'Amortizuojanti sėdynė',
      'Sustriprinta pakaba',
      'Comfort sėdynė',
    ],
    images: [
      { src: './MJV373/1.jpg' },
      { src: './MJV373/2.jpg' },
      { src: './MJV373/3.jpg' },
    ],
    show: false,
  },

];

const cards3 = [

  {
    id: 8,
    name: 'Volkswagen Crafter',
    nr: 'MIJ 050',
    year: '2020m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,3m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
    ],
    images: [
      { src: './MIJ050/1.jpg' },
      { src: './MIJ050/2.jpg' },
      { src: './MIJ050/3.jpg' },
    ],
    show: false,
  },
  {
    id: 9,
    name: 'Mercedes-benz',
    nr: 'MTR 868',
    year: '2020m.',
    gearbox: 'Mechaninė pavarų dėžė',
    size: '4,7m. 1,8m. 1,9m',
    properties: [
      'Oro kondicionierius',
      'Led apšvietimas',
      'Parkavimo jutikliai',
      'Galinio vaizdo kamera',
      'Comfort sėdynė',
    ],
    images: [
      { src: './MTR868/1.jpeg' },
      { src: './MTR868/2-min.jpeg' },
      { src: './MTR868/3-min.jpeg' },
      { src: './MTR868/4-min.jpg' },
    ],
    show: false,
  },
]

export default function MainPage() {
  const [index, setIndex] = useState(-1);

  const imagesGallery = [
    {
      src: './gallery/1.jpg',
      original: './gallery/1.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/2.jpg',
      original: './gallery/2.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/3.jpg',
      original: './gallery/3.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/4.jpg',
      original: './gallery/4.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/5.jpg',
      original: './gallery/5.jpg',
      width: 320,
      height: 124,
      caption: '',
    },
    {
      src: './gallery/6.jpg',
      original: './gallery/6.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/7.jpg',
      original: './gallery/7.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/8.jpg',
      original: './gallery/8.jpg',
      width: 320,
      height: 194,
      caption: '',
    },
    {
      src: './gallery/9.jpg',
      original: './gallery/9.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/10.jpg',
      original: './gallery/10.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
    {
      src: './gallery/11.jpg',
      original: './gallery/.jpg',
      width: 320,
      height: 174,
      caption: '',
    },
  ];

  const showDialog = () => {
    confirmAlert({
      title: 'Užklausa gauta',
      message: 'Jūsų užklausa gauta, susisieksime netrukus',
      buttons: [
        {
          label: 'Uždaryti',
        },
      ],
    });
  };

  const currentImage = imagesGallery[index];
  const nextIndex = (index + 1) % imagesGallery.length;
  const nextImage = imagesGallery[nextIndex] || currentImage;
  const prevIndex = (index + imagesGallery.length - 1) % imagesGallery.length;
  const prevImage = imagesGallery[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const [cards, setCards] = useState(cards1);
  const [cardsSecond, setCardsSecond] = useState(cards2);
  const [cardsThird, setCardsThird] = useState(cards3);

  const moreInfo = (index) => {
    const newCards = [...cards];
    newCards[index].show = !newCards[index].show;
    setCards(newCards);
  };

  const moreInfo2 = (index) => {
    const newCards = [...cardsSecond];
    newCards[index].show = !newCards[index].show;
    setCardsSecond(newCards);
  };

  const moreInfo3 = (index) => {
    const newCards = [...cardsThird];
    newCards[index].show = !newCards[index].show;
    setCardsThird(newCards);
  };

  const [mobile, setMobile] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 400) {
      setMobile(true);
    } else setMobile(false);
  }, [width]);

  const { form, use, submit } = useForm({
    defaultValues: { name: '', email: '', phone: '', text: '' },
    onSubmit: (values) => alert(JSON.stringify(values, undefined, 2)),
  });
  const errors = use('errors', { errorWithTouched: true });

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    question: '',
  };

  const validationSchema = () => {
    const schema = {
      name: Yup.string('Laukas privalomas').required('Laukas privalomas'),
      email: Yup.string().required('Laukas privalomas'),
      phone: Yup.string().required('Laukas privalomas'),
      question: Yup.string().required('Laukas privalomas'),
    };

    return Yup.object().shape(schema);
  };
  const { post: manageJob } = useFetch('/Contact/SendContact');
  const handleContactSubmit = async (data) => {
    showDialog();
    await manageJob('', {
      name: data.name,
      phone: data.phone,
      email: data.email,
      question: data.question,
    });
  };

  return (
    <>
      <Element name="main">
        <Container id="main">
          <SideWrapper>
            <LeftSide>
              <Title>
                Krovininių mikroautobusų nuoma{' '}
                <span style={{ color: 'red' }}>visoje Lietuvoje</span>
              </Title>
              <Description>
                Mums svarbus kiekvienas mūsų klientas!

              </Description>
              <Description>
                Dirbame, kad būtumėte patenkinti.
              </Description>
              <Description>
                Siūlome Jums trumpalaikę ir ilgalaikę krovininių mikroautobusų nuomą Lietuvoje ir Europoje be vairuotojo.
              </Description>
              <ButtonsWrapper>
                <Link to="contactForm" smooth={false}>
                  <ButtonRed>Kontaktai</ButtonRed>

                </Link>
                <Link to="buses" smooth={false}>
                  <ButtonBlack>Mikroautobusai</ButtonBlack>
                </Link>
              </ButtonsWrapper>
            </LeftSide>
            <RightSide>
              <img src="/img/mainBusBlack.png" alt="bus" />
            </RightSide>
          </SideWrapper>
        </Container>
      </Element>
      <SecondSection black={true}>
        <Wrapper>
          {' '}
          <SecondSectionTitle>
            Kodėl verta rinktis
            <span style={{ color: 'red' }}>
              {' '}
              ID Ratai mikroautobusų nuomą?{' '}
            </span>
          </SecondSectionTitle>
          <SecondSectionDescription>
            Siekiame teikti aukščiausios kokybės paslaugas, užtikrinti geriausią kainos ir kokybės santykį, patenkinti visus klientų poreikius ir lūkesčius
          </SecondSectionDescription>
          <ListWrapper>
            <ul>
              <li>
                <AiOutlineCheckSquare />
                Mikroautobusai yra švarūs ir techniškai
                tvarkingi
              </li>
              <li>
                <AiOutlineCheckSquare />
                Civilinis ir KASKO nuomos draudimas
              </li>
              <li>
                <AiOutlineCheckSquare />
                Lietuvos kelių mokesčiai
              </li>
              <li>
                <AiOutlineCheckSquare />
                Mikroautobusų nuoma 24/7
              </li>
              <li>
                <AiOutlineCheckSquare />
                Turime <em>EURO 6</em> mikroautobusų
              </li>
            </ul>

            <ul>
              <li>
                <AiOutlineCheckSquare />
                Pagalba kelyje
              </li>
              <li>
                <AiOutlineCheckSquare />
                Užtenka B kategorijos
              </li>
              <li>
                <AiOutlineCheckSquare />
                Galime suteikti sekimo nuorodą
              </li>
              <li>
                <AiOutlineCheckSquare />
                Krovinių skyrius turi sumontuotą LED apšvietimą.
              </li>
            </ul>
          </ListWrapper>
        </Wrapper>
      </SecondSection>

      <SecondSection black={false} id="terms">
        <Wrapper>
          {' '}
          <SecondSectionTitle black={false}>
            Mikroautobuso
            <span style={{ color: 'red' }}> nuomos sąlygos </span>
          </SecondSectionTitle>
          <ListWrapper black={false}>
            <ul>
              <MiniTitle>Kas gali vairuoti mikroautobusą?</MiniTitle>
              <RedLine></RedLine>
              <MiniDescription>
                Nuomojamą mikroautobusą gali vairuoti asmuo, turintis 2 m. vairavimo patirtį, galiojantį tarptautinius reikalavimus atitinkantį vairuotojo pažymėjimą.
              </MiniDescription>

              <MiniTitle>Papildomos sąlygos</MiniTitle>
              <RedLine></RedLine>
              <MiniDescription>
                Mikroautobusas išnuomojamas ir grąžinamas su pilnu baku kuro. Taikomas ridos apribojimas 450km/para. Už papildomus kilometrus taikomas atskiras mokestis. Klausti idividualiai.
              </MiniDescription>
            </ul>

            <ul>
              <MiniTitle>Mokėjimai už nuomą</MiniTitle>
              <RedLine></RedLine>
              <MiniDescription>
                Klientas, pasiimdamas mikroautobusą, sumoka automobilio nuomos mokestį už visą nuomos laikotarpį.
              </MiniDescription>

              <MiniTitle>Užstatas už mikroautobuso nuomą</MiniTitle>
              <RedLine></RedLine>
              <MiniDescription>
                Klientas, pasiimdamas mikroautobusą, sumoka užstatą dėl galimų nuostolių, jeigu tokių atsirastų nuomos laikotarpiu. Užstato dydis 250 EUR. Grąžinus tvarkingą mikroautobusą užstatas grąžinamas.
              </MiniDescription>

              <MiniTitle>Nuomos sutartis</MiniTitle>
              <RedLine></RedLine>
              <MiniDescription>
                Siūlome susipažinti su nuomos sutartimi.{' '}
                <a href="./invoice.docx" download="Nuomos sutartis">
                  <ButtonRed> Peržiūrėti</ButtonRed>
                </a>
              </MiniDescription>
            </ul>
          </ListWrapper>
        </Wrapper>
      </SecondSection>

      <CenteredSection black={false} id="buses">
        <Element name="buses"></Element>
        <CenteredWrapper>
          <CenteredSecondSectionTitle black={false}>
            Mikroautobusai
          </CenteredSecondSectionTitle>

          <CardWrapper>
            <CardRow>
              {cards.map((c, i) => {
                return (
                  <Card>
                    <Carousel
                      className="carousel"
                      isAutoPlaying={true}
                      hasMediaButton={false}
                      hasIndexBoard={false}
                      widgetsHasShadow={false}
                      images={c.images}
                      hasThumbnails={false}
                      shouldMaximizeOnClick={true}
                      shouldMinimizeOnClick={true}
                      style={{ height: 242, width: mobile ? 240 : 350 }}
                    />
                    <span style={{ fontWeight: 'bold', fontSize: '28px' }}>
                      {c.name}
                    </span>
                    <span>
                      {' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Valst. nr.:
                      </span>{' '}
                      {c.nr}, {c.year}
                    </span>
                    <span>{c.gearbox}</span>
                    <span style={{ fontWeight: 'bold' }}>
                      Krovininio skyriaus matmenys:
                    </span>
                    <span>{c.size}</span>

                    {c.show && (
                      <Card key={i} style={{ alignItems: 'baseline' }}>
                        {c.properties.map((property) => {
                          return <li>{property}</li>;
                        })}
                      </Card>
                    )}

                    <ButtonRed
                      style={{ marginTop: '10px' }}
                      onClick={() => moreInfo(i)}
                    >
                      Daugiau{' '}
                    </ButtonRed>
                  </Card>
                );
              })}
            </CardRow>
          </CardWrapper>
          <CardWrapper>
            <CardRow>
              {cardsSecond.map((c, i) => {
                return (
                  <Card>
                    <Carousel
                      className="carousel"
                      isAutoPlaying={true}
                      hasMediaButton={false}
                      hasIndexBoard={false}
                      widgetsHasShadow={false}
                      images={c.images}
                      hasThumbnails={false}
                      shouldMaximizeOnClick={true}
                      shouldMinimizeOnClick={true}
                      style={{ height: 242, width: mobile ? 240 : 350 }}
                    />
                    <span style={{ fontWeight: 'bold', fontSize: '28px' }}>
                      {c.name}
                    </span>
                    <span>
                      {' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Valst. nr.:
                      </span>{' '}
                      {c.nr}, {c.year}
                    </span>
                    <span>{c.gearbox}</span>
                    <span style={{ fontWeight: 'bold' }}>
                      Krovininio skyriaus matmenys:
                    </span>
                    <span>{c.size}</span>

                    {c.show && (
                      <Card key={i}>
                        {c.properties.map((property) => {
                          console.log(property);
                          return <li>{property}</li>;
                        })}
                      </Card>
                    )}

                    <ButtonRed
                      style={{ marginTop: '10px' }}
                      onClick={() => moreInfo2(i)}
                    >
                      Daugiau{' '}
                    </ButtonRed>
                  </Card>
                );
              })}
            </CardRow>
          </CardWrapper>

          <CardWrapper >
            <CardRow style={{ justifyContent: 'center' }}>
              {cardsThird.map((c, i) => {
                return (
                  <Card>
                    <Carousel
                      className="carousel"
                      isAutoPlaying={true}
                      hasMediaButton={false}
                      hasIndexBoard={false}
                      widgetsHasShadow={false}
                      images={c.images}
                      hasThumbnails={false}
                      shouldMaximizeOnClick={true}
                      shouldMinimizeOnClick={true}
                      style={{ height: 242, width: mobile ? 240 : 350 }}
                    />
                    <span style={{ fontWeight: 'bold', fontSize: '28px' }}>
                      {c.name}
                    </span>
                    <span>
                      {' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Valst. nr.:
                      </span>{' '}
                      {c.nr}, {c.year}
                    </span>
                    <span>{c.gearbox}</span>
                    <span style={{ fontWeight: 'bold' }}>
                      Krovininio skyriaus matmenys:
                    </span>
                    <span>{c.size}</span>

                    {c.show && (
                      <Card key={i}>
                        {c.properties.map((property) => {
                          return <li>{property}</li>;
                        })}
                      </Card>
                    )}

                    <ButtonRed
                      style={{ marginTop: '10px' }}
                      onClick={() => moreInfo3(i)}
                    >
                      Daugiau{' '}
                    </ButtonRed>
                  </Card>
                );
              })}
            </CardRow>
          </CardWrapper>

        </CenteredWrapper>



      </CenteredSection>

      <CenteredSection black={false} id="rent-price">
        <CenteredWrapper>
          <CenteredSecondSectionTitle black={false}>
            Nuomos kainos
          </CenteredSecondSectionTitle>

          <PriceWrap>
            <Prices>
              <SinglePrice>
                <span>1-5 Paros</span>
                <span
                  style={{
                    borderTop: '1px solid red',
                    width: '80%',
                    height: '2px',
                  }}
                ></span>
                <span style={{ fontSize: '18px' }}>55€/para</span>
              </SinglePrice>

              <SinglePrice>
                <span>6-15 Paros</span>
                <span
                  style={{
                    borderTop: '1px solid red',
                    width: '80%',
                    height: '2px',
                  }}
                ></span>
                <span style={{ fontSize: '18px' }}>50€/para</span>
              </SinglePrice>

              <SinglePrice>
                <span>Nuo 16 parų</span>
                <span
                  style={{
                    borderTop: '1px solid red',
                    width: '80%',
                    height: '2px',
                  }}
                ></span>
                <span style={{ fontSize: '18px' }}>45€/para</span>
              </SinglePrice>

              <SinglePrice>
                <span>Mėnuo</span>
                <span
                  style={{
                    borderTop: '1px solid red',
                    width: '80%',
                    height: '2px',
                  }}
                ></span>
                <span style={{ fontSize: '18px' }}>1100€</span>
              </SinglePrice>
            </Prices>
            <div>
              <Pvm style={{ paddingRight: '18px' }}>
                {' '}
                <span style={{ color: 'red', marginRight: '3px' }}>
                  *{' '}
                </span>{' '}
                Kainos nurodytos be PVM
              </Pvm>

              <Pvm>
                {' '}
                <span style={{ color: 'red', marginRight: '3px' }}>
                  *{' '}
                </span>{' '}
                Kainos nurodytos Lietuvoje
              </Pvm>
            </div>

            <PriceDescription>
              <img src="./img/whiteBus.png" alt="whitebus"></img>

              <div>
                <div
                  style={{
                    fontSize: '30px',
                    fontWeight: 'bolder',
                    marginLeft: '35px',
                    marginTop: '40px',
                  }}
                >
                  Mokestis viršijus kilometražą
                </div>
                <div style={{ marginLeft: '35px', marginTop: '10px' }}>
                  {' '}
                  Į šią kainą yra įtraukta 450 kilometrų, viršijus kilometražą papildomo kilometro kaina - 0,12 Eur. Pvz., jeigu jūs per dieną nuvažiuojate 550 km, tai dienos limitas bus viršytas 100 km, todėl kiekvienas viršytas kilometras yra papildomai apmokestinamas (100 x 0,12 = 12 Eur; už viršytus kilometrus papildomai reikės sumokėti 12 Eur).
                </div>

                <Link to="contactForm" smooth={false}>
                  <ButtonRed
                    style={{
                      marginLeft: '35px',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    Kontaktai
                  </ButtonRed>

                </Link>
              </div>
            </PriceDescription>
          </PriceWrap>
        </CenteredWrapper>
      </CenteredSection>
      <CenteredSection>
        <div style={{ width: '80%' }}>
          <Gallery
            images={imagesGallery}
            onClick={handleClick}
            enableImageSelection={false}
          />
          {!!currentImage && (
            /* @ts-ignore */
            <Lightbox
              mainSrc={imagesGallery[index].original}
              imageTitle={imagesGallery[index].caption}
              mainSrcThumbnail={imagesGallery[index].src}
              nextSrc={nextImage.original}
              nextSrcThumbnail={nextImage.src}
              prevSrc={prevImage.original}
              prevSrcThumbnail={prevImage.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </div>
      </CenteredSection>

      <Element name="contacts" id="contactForm" style={{ marginTop: '65px' }}>
        <div>
          <Contacts>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '36px',
                marginRight: '45px',
              }}
            >
              {' '}
              Parašykite mums
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                await handleContactSubmit(values);
                resetForm();
              }}
              validateOnBlur={false}
              validateOnMount={false}
              validateOnChange={false}
            >
              {({ errors, setFieldValue }) => (
                <ContactForm>
                  <Form>
                    <Inputs>
                      <Field
                        style={{ height: '30px' }}
                        placeholder="Vardas"
                        id="name"
                        name="name"
                        required
                        error={errors.name}
                      />
                      <Field
                        style={{ height: '30px' }}
                        placeholder="El. paštas"
                        id="email"
                        name="email"
                        type="email"
                        required
                        error={errors.email}
                      />
                      <Field
                        style={{ height: '30px' }}
                        placeholder="Telefono numeris"
                        id="phone"
                        name="phone"
                        required
                        error={errors.phone}
                      />
                      <Field
                        style={{ height: '60px' }}
                        placeholder="Pageidaujama paslauga"
                        id="question"
                        name="question"
                        required
                        error={errors.question}
                      />
                      <ButtonRedBtn type="submit">Siųsti</ButtonRedBtn>
                    </Inputs>
                  </Form>
                </ContactForm>
              )}
            </Formik>
          </Contacts>
        </div>
      </Element>
    </>
  );
}

const Pvm = styled.div`
  display: flex;
  justify-content: end;
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
  margin-right: 55px;

  @media (max-width: 580px) {
    margin-right: 10px;
    font-size: 16px;
  }
`;

const PriceDescription = styled.div`
  color: white;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  padding: 65px;
  img {
    width: 340px;
    height: 280px;
  }

  @media (max-width: 1200px) {
    img {
      width: 0px;
      height: 0px;
    }
    margin: 0;
    padding: 0;
  }
`;

const SinglePrice = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  line-height: 60px;
  font-size: 30px;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
  margin-top: 15px;
`;

const Prices = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-evenly;
  div:not(:last-child) {
    border-right: 1px solid white;
  }

  @media (max-width: 1200px) {
    flex-direction: column;

    div:not(:last-child) {
      border-right: 0px solid white;
      border-bottom: 1px solid white;
    }
  }
`;

const PriceWrap = styled.div`
  background-color: black;
  width: 65%;
  color: white;
  align-self: center;
  margin-top: 25px;
  border-radius: 20px;

  @media (max-width: 1200px) {
    width: 80%;
    margin: 0;
  }

  @media (max-width: 580px) {
    width: 95%;
    margin: 0;
  }
`;

const CenteredSecondSectionTitle = styled.div`
  margin-top: 45px;
  font-size: 35px;
  font-style: normal;
  font-weight: bold;
  line-height: 1.1;
  align-self: center;
`;

const CardWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;

  @media (max-width: 500px) {
    justify-content: start;
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1180px) {
    flex-direction: column;
    width: 400px;
  }

  @media (max-width: 500px) {
    justify-content: start;
    align-items: start;
    width: 200px;
    margin-left: 5vh;
  }

  @media (max-width: 390px) {
    width: 100px;
  }
`;

const Card = styled.div`
  padding: 20px;
  border-radius: 5%;
  background-color: rgb(244, 244, 244);
  display: flex;
  line-height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  img {
    border-radius: 5%;
    // height: 262px;
  }

  @media (max-width: 1850px) {
    margin-right: 10px;
  }

  @media (max-width: 1160px) {
    margin-bottom: 15px;
  }

  @media (max-width: 500px) {
  }

  @media (max-width: 390px) {
    width: 270px;
  }

  @media (max-width: 330px) {
    width: 250px;
  }
`;

const CenteredWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 500px) {
    justify-content: start;
    width: 100%;
    padding-left: 0px;
  }
`;

const CenteredSection = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${(props) => (props.black ? 'black' : 'white')};
  color: ${(props) => (props.black ? 'white' : 'black')};
  @media (max-width: 500px) {
    justify-content: start;
    // align-items: start;
    // padding-left: 25px;
  }
  padding-bottom: 60px;
`;

const RedLine = styled.div`
  border-top: 2px solid red;
  width: 20%;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const MiniDescription = styled.p`
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  max-width: 70%;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

const MiniTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 3;
  margin-top: 20px;
  li {
    svg {
      color: red;
      height: 25px;
      width: 25px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    line-height: 1.9;
  }

  @media (max-width: 500px) {
    justify-content: start;
  }
`;

const SecondSection = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${(props) => (props.black ? 'black' : 'white')};
  color: ${(props) => (props.black ? 'white' : 'black')};
  @media (max-width: 500px) {
    justify-content: start;
    align-items: start;
    padding-left: 25px;
  }
  padding-bottom: 60px;
`;

const SecondSectionDescription = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 550;
  text-align: left;
  word-wrap: break-word;
`;

const SecondSectionTitle = styled.div`
  margin-top: 45px;
  font-size: 35px;
  font-style: normal;
  font-weight: bold;
  line-height: 1.1;
`;

const Wrapper = styled.section`
  width: 65%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (max-width: 500px) {
    justify-content: start;
    align-items: start;
    padding-left: 25px;
  }
`;

const LeftSide = styled.div``;

const RightSide = styled.div`
  @media (max-width: 1120px) {
    img {
      height: 300px;
      width: 440px;
    }
  }

  @media (max-width: 900px) {
    img {
      display: none;
    }
  }
`;

const SideWrapper = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  max-width: 62%;
   @media (max-width: 1100px) {
    padding-top: 120px;
  }
   @media (max-width: 562px) {
    padding-top: 160px;
  }
  @media (max-width: 450px) {
    max-width: 100%;
  }
`;

const Title = styled.div`
  font-size: 48px;
  font-style: normal;
  font-weight: bold;
  line-height: 1.1;
  @media (max-width: 450px) {
    font-size: 32px;
  }
`;
const Description = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 2;
  color: rgb(26, 27, 31);
  font-weight: 550;
  text-align: left;
  word-wrap: break-word;
`;
const ButtonRed = styled.div`
  color: white;
  border-radius: 10px;
  width: 225px;
  height: 60px;
  background-color: rgb(236, 28, 36);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;

  @media (max-width: 600px) {
    width: 200px;
  }
`;

const ButtonBlack = styled.div`
  color: white;
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
  width: 225px;
  height: 60px;
  background-color: black;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 200px;
  }

   @media (max-width: 450px) {
   margin-left: 0px;
   margin-top: 10px;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: row;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-around;
  height: 280px;
  width: 100%;
  input {
    border: 0;
    border-bottom: 1px solid black;
  }

  input:focus {
    outline: none;
    border: 2px solid blue;
    border-radius: 25px;
  }
`;

const ContactForm = styled.div`
  width: 40%;

  @media (max-width: 1120px) {
    width: 90%;
  }
`;

const ButtonRedBtn = styled.button`
  color: white;
  border-radius: 10px;
  width: 225px;
  height: 60px;
  background-color: rgb(236, 28, 36);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;

  @media (max-width: 600px) {
    width: 200px;
  }
`;
