import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import { useState } from 'react';
import Loader from '../../../../shared/Loader/Loader.js';
import React from 'react';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import { useNavigate } from 'react-router';
const Availability = () => {
  const { get: getAvailability, response: getAvailabilityResponse } = useFetch(
    '/Reservation/GetAvailability',
  );

  const { get: GetSingleReservation } = useFetch(
    '/Reservation/GetSingleReservation',
  );

  const [Availability, setAvailability] = useState();
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  useEffectOnce(() => {
    getAvailabilityFn();
  });

  const getAvailabilityFn = async () => {
    setLoading(true);
    const response = await getAvailability();
    if (getAvailabilityResponse.status > 200) {
    } else {
      setAvailability(response);
      setLoading(false);
    }
  };

  function cellColor(dateFrom, dateTo) {
    const start = new Date(dateFrom);
    const end = new Date(dateTo);
    const date = new Date();
    if (date > start && date < end) {
      return true;
    } else {
      return false;
    }
  }

  async function handleRedirect(id) {
    const res = await GetSingleReservation(id);
    history('/admin/managment/reservations/create', {
      state: {
        data: res,
        from: 'availability',
      },
    });
  }

  return (
    <Styled.ContentWrapper>
      <Sidebar></Sidebar>
      {(loading && (
        <div>
          <Loader></Loader>
        </div>
      )) || (
        <div style={{ width: '100%' }}>
          <Styled.Availability
            style={{ marginLeft: '35px', marginTop: '35px' }}
          >
            <thead>
              <tr>
                <th>Valst. Nr.</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Availability &&
                Availability.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td key={idx}>{item.LicensePlate}</td>
                      {item.DatesTo.map((date, idx) => {
                        return (
                          <Styled.Td
                            onClick={() => {
                              handleRedirect(item.Reservations[idx]);
                            }}
                            key={idx}
                            cellcolor={cellColor(item.DatesFrom[idx], date)}
                            cellindex={idx}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginLeft: '10px',
                                marginRight: '10px',
                              }}
                            >
                              {item.DatesFrom[idx].slice(0, -3)}h -{' '}
                              {date.slice(0, -3)}h
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                {item.Renter[idx]}
                              </span>
                            </div>
                          </Styled.Td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Styled.Availability>
        </div>
      )}
    </Styled.ContentWrapper>
  );
};
export default Availability;
