/* eslint-disable react/jsx-no-comment-textnodes */
import Styled from './Sidebar.styles';
import { useNavigate } from 'react-router';
import { useUserState } from '../../context/UserContext/UserContext';
const Sidebar = () => {
  const history = useNavigate();
  const userState = useUserState();
  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 bg-light"
      style={{ width: '280px' }}
    >
      <a className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <svg className="bi me-2" width="40" height="32"></svg>
        <span className="fs-4">IDRATAI</span>
      </a>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <Styled.Item onClick={() => history('/admin/managment/vehicles')}>
          Automobiliai
        </Styled.Item>
        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => history('/admin/managment/reservations')}
            >
              Rezervacijos
            </Styled.Item>
          </div>
        )}
        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => {
                history('/admin/managment/check/reservation');
              }}
            >
              Tikrinti laisvus laikus
            </Styled.Item>
          </div>
        )}

        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => {
                history('/admin/managment/check/availability');
              }}
            >
              Užimtumas
            </Styled.Item>
          </div>
        )}

        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => {
                history('/admin/managment/jobs');
              }}
            >
              Darbų sąrašas
            </Styled.Item>
          </div>
        )}

        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => {
                history('/admin/managment/jobs/applied');
              }}
            >
              Atlikti darbai
            </Styled.Item>
          </div>
        )}

        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => {
                history('/admin/managment/history/reservations');
              }}
            >
              Rezervacijų istorija
            </Styled.Item>
          </div>
        )}

        {userState.Role === 0 && (
          <div>
            <Styled.Item
              onClick={() => {
                history('/admin/managment/calculator');
              }}
            >
              Skaičiuoklė
            </Styled.Item>
          </div>
        )}
      </ul>
    </div>
  );
};
export default Sidebar;
