import styled from 'styled-components/macro';
import {
  Box as CheckboxBox,
  Label as CheckboxLabel,
  Checkbox,
} from '../Checkbox/Checkbox.styles';

const Box = styled(CheckboxBox)`
  border-radius: 26px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.15s;
  }

  @media (max-width: 662px) {
    top: 8px;
  }

  @media (max-width: 543px) {
    top: 15px;
  }
`;

const Label = styled(CheckboxLabel)``;

const Radio = styled(Checkbox)`
  input {
    &:checked + ${Label} {
      ${Box} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

const Styled = {
  Radio,
  Label,
  Box,
};

export default Styled;
