import styled from 'styled-components/macro';

const Styled = {
  Item: styled.div`
    &:hover {
      background-color: blue;
      cursor: pointer;
      color: white;
      border-radius: 15px;
      padding-left: 15px;
    }
    margin-left: 10px;
    font-size: 16px;
    color: black;
  `,
  Table: styled.div`
    width: 95%;
  `,
};

export default Styled;
