import styled from 'styled-components/macro';
const StyledFreeTime = {
  Content: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 80px;
    div {
      max-width: 280px;
    }
  `,

  Time: styled.div`
    margin-top: 25px;
  `,
};

export default StyledFreeTime;
