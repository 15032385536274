import styled from 'styled-components/macro';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { mediaDown } from '../../../../utils/styles/media';

const Styled = {
  Page: styled.div`
    height: 100vh;
    width: 100%;
  `,
  Table: styled.div`
    width: 95%;
  `,
  ContentWrapper: styled.div`
    height: calc(100vh - 160px);
    display: flex;
    flex-direction: row;
  `,

  DateInput: styled.div`
    margin-top: 20px;
    .react-datepicker-wrapper input {
      height: 50px;
      width: 275px;
      text-align: center;
    }
  `,

  RentPrice: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-weight: bold;
    font-size: 24px;
  `,
  FieldWrapper: styled.div`
    display: flex;
    width: 100%;
    ${mediaDown('md')`
   width: 425px;
  `}
    flex-direction: column;
    label:not(:first-child) {
      margin-top: 15px;
    }
  `,

  Availability: styled.table`
    table,
    th,
    td {
      border: 1px solid black;
      font-size: 18px;
    }
  `,

  Td: styled.td`
    background-color: ${(props) =>
      props.cellcolor && props.cellindex === 0 ? 'red' : ''};
  `,

  FilterBlock: styled.div`
    border-radius: 20px;
    border: 1px solid blue;
    margin: 5px;
    padding: 10px;
    background-color: ${(props) => (props.active ? 'lightblue' : '')};
    cursor: pointer;
  `,
};

export default Styled;
