import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Assets
import CloseIcon from '../../../assets/svg/CloseIcon';
import LogoIcon from '../../../assets/svg/Logo';
import { useNavigate } from 'react-router';
import {
  useUserDispatch,
  useUserState,
} from '../../context/UserContext/UserContext';
export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const userState = useUserState();
  let history = useNavigate();
  const sessionDispatch = useUserDispatch();

  const handleLogout = () => {
    sessionDispatch({ type: 'LOGOUT' });
    history('/');
  };
  return (
    <Wrapper className="animate whiteBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          <h1 className="whiteColor font20" style={{ marginLeft: '15px' }}>
            fanatic
          </h1>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: '10px 15px' }}
            to="home"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Pradžia
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: '10px 15px' }}
            to="services"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Privalumai
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: '10px 15px' }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Nuoma
          </Link>
        </li>

        <li className="semiBold font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: '10px 15px' }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Kontaktai
          </Link>
        </li>
      </UlStyle>
      <UlStyle className="flexSpaceCenter">
        {!userState.IsLoggedIn && (
          <li
            className="semiBold font15 pointer"
            onClick={() => {
              history('/login');
            }}
          >
            <a style={{ padding: '10px 30px 10px 0' }}>Log in</a>
          </li>
        )}

        {userState.IsLoggedIn && (
          <>
            <li
              className="semiBold font15 pointer"
              onClick={() => {
                history('/admin/managment/vehicles');
              }}
            >
              <a style={{ padding: '10px 30px 10px 0' }}>
                {userState.Username}
              </a>
            </li>

            <li
              className="semiBold font15 pointer flexCenter"
              onClick={handleLogout}
            >
              <a className="radius8 lightBg" style={{ padding: '10px 15px' }}>
                Atsijungti
              </a>
            </li>
          </>
        )}
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? '0px' : '-400px')};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
