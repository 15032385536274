import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { useEffectOnce } from 'react-use';
import useFetch from 'use-http';
import * as Yup from 'yup';
import Styled from '../Form.styles';
import { useNavigate, useLocation } from 'react-router';
import Input from '../../../../shared/Input/Input';
import { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import Radio from '../../../../shared/Radio/Radio';
import CustomCalendar from '../../../../shared/Calendar/CustomCalendar';
import Page from '../../../../shared/Page/Page';
import * as moment from 'moment';
import Hero from '../../../../shared/Hero/Hero';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
const ReserveVehicle = () => {
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [vehiclePrices, setVehiclePrices] = useState();
  const [overLaps, setOverLaps] = useState(false);
  const [dayCount, setDayCount] = useState(0);
  const [calculatedRentPrice, setCalculatedRentPrice] = useState();
  const [selectedOption, setSelectedOption] = useState('Person');
  const { post: manageReservation } = useFetch(
    '/Reservation/PerformReservation',
  );
  const history = useNavigate();
  const location = useLocation();

  const reservation = location?.state?.data;
  const back = location?.state?.from;
  const [isPaidChecked, setIsPaidChecked] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [startDate, setStartDate] = useState(reservation?.reservationFrom);
  const [endDate, setEndDate] = useState(reservation?.reservationTo);

  const [query, setQuery] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const [reservationSuggestions, setReservationSuggestions] = useState([]);
  const { get: vehicleOptions } = useFetch(
    '/Vehicles/GetVehiclesOptions/false',
  );

  const [initialValues, setInitialValues] = useState({
    driverName: reservation?.DriverName,
    driverSurname: '',
    driverPhoneNumber: reservation?.DriverPhoneNumber,
    driverIdNumber: reservation?.DriverIdNumber,
    companyName: reservation?.CompanyName,
    companyAdditionalInfo: reservation?.CompanyAdditionalInfo,
    companyPhone: reservation?.CompanyPhone,
    companyAddress: reservation?.CompanyAddress,
    driverAdress: reservation?.DriverAddressLine,
    reservationFrom: reservation?.ReservationFrom,
    reservationTo: reservation?.ReservationTo,
    isCompany: reservation?.isCompany ?? false,
    licenseNumber: reservation?.DriverLicenseNumber,
    companyCode: reservation?.CompanyCode,
    pvmCode: reservation?.PvmCode,
    rentPrice: reservation?.RentPrice,
    deposit: reservation?.Deposit ?? '250',
    dayRentCount: reservation?.RentDayCount ?? 1,
    rentDayPrice: reservation?.OneDayPrice ?? 55,
    isPaid: reservation?.IsPaid,
  });

  const { get: reservationOptions } = useFetch(
    '/Reservation/GetReservationUsers',
  );

  const { get: GetSingleReservation } = useFetch(
    '/Reservation/GetSingleReservation',
  );

  const formRef = useRef();
  async function selectedSuggestedOption(val) {
    const res = await GetSingleReservation(val);

    const hours = new Date();
    hours.setHours(10, 0, 0, 0);
    hours.setUTCHours(10, 0, 0, 0);
    const startEnd = hours.toISOString().substring(0, 16);
    setSelectedOption(res?.IsCompany ? 'Business' : 'Person');
    const newValues = {
      driverName: res?.DriverName,
      driverSurname: '',
      driverPhoneNumber: res?.DriverPhoneNumber,
      driverIdNumber: res?.DriverIdNumber,
      companyName: res?.CompanyName,
      companyAdditionalInfo: res?.CompanyAdditionalInfo,
      driverAdress: res?.DriverAddressLine,
      reservationFrom: startEnd,
      reservationTo: startEnd,
      isCompany: res?.isCompany ?? false,
      licenseNumber: res?.DriverLicenseNumber,
      companyCode: res?.CompanyCode,
      pvmCode: res?.PvmCode,
      rentPrice: res?.RentPrice,
      companyPhone: res?.CompanyPhone,
      companyAddress: res?.CompanyAddress,
      deposit: res?.Deposit,
      dayRentCount: res?.RentDayCount ?? 1,
      rentDayPrice: res?.OneDayPrice ?? 55,
      isPaid: res?.IsPaid,
      vehicle: { value: formRef.current?.values?.vehicle?.value },
    };

    setInitialValues(newValues);
  }

  useEffect(() => {
    if (reservation) {
      setStartDate(reservation.ReservationFrom);
      setEndDate(reservation.ReservationTo);
      if (reservation.IsCompany) setSelectedOption('Business');
      setIsPaidChecked(reservation.IsPaid);
      setSelectedVehicle({
        value: reservation?.Vehicle?.Id,
        label: reservation?.Vehicle?.Plate,
      });
      formRef.current.values.vehicle = {
        value: reservation?.Vehicle?.Id,
        label: reservation?.Vehicle?.Plate,
      };

      formRef.current.values.reservationFrom = reservation.ReservationFrom;
      formRef.current.values.reservationTo = reservation.ReservationTo;
    }
  }, [reservation]);

  useEffectOnce(() => {
    getVehicleOptions();
  });

  const getVehicleOptions = async () => {
    const response = await vehicleOptions();
    if (response) {
      const tmp = [];
      const tmpPrices = [];
      response.forEach((d) => {
        tmp.push({ value: d.Value, label: d.Label });
      });
      setVehicles(tmp);
      setVehiclePrices(tmpPrices);
    }
  };

  function dateChanged(date) {
    const fromDate = new Date(date.startDate);
    const toDate = new Date(date.endDate);

    setStartDate(fromDate);
    setEndDate(toDate);

    formRef.current.values.reservationFrom = fromDate;
    formRef.current.values.reservationTo = toDate;

    const dayCount = diffDays(fromDate, toDate);
    if (formRef) {
      formRef.current.values.dayRentCount = dayCount;
      formRef.current.values.rentPrice =
        dayCount * formRef.current.values.rentDayPrice;
    }

    setDayCount(dayCount);
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => setInputSearch(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const asyncFn = async () => {
      if (inputSearch) {
        const options = await reservationOptions(inputSearch);
        setReservationSuggestions(options);
      } else setReservationSuggestions([]);
    };
    asyncFn();
  }, [inputSearch]);

  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, [startDate, endDate]);

  const diffDays = (date, otherDate) =>
    Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

  const validationSchema = Yup.object().shape({});

  const onError = {
    color: 'red',
    border: '2px solid #d66',
  };

  const handleEdit = async (data) => {
    setSubmitted(true);
    const fromDate = new Date(data.reservationFrom);
    const toDate = new Date(data.reservationTo);
    await manageReservation('', {
      ReservationId: reservation?.Id,
      VehicleId: data?.vehicle?.value,
      DriverName: data.driverName,
      DriverSurname: data.driverSurname,
      DriverPhoneNumber: data.driverPhoneNumber,
      DriverAdress: data.driverAdress,
      DriverIdNumber: data.driverIdNumber,
      CompanyName: data.companyName,
      CompanyAdditionalInfo: data.companyAdditionalInfo,
      ReservationFrom: fromDate.toUTCString(),
      ReservationTo: toDate.toUTCString(),
      IsCompany: selectedOption === 'Business',
      DriverLicenseNumber: data.licenseNumber,
      CompanyCode: data.companyCode,
      PvmCode: data.pvmCode,
      RentPrice: data.rentPrice,
      CompanyPhone: data?.companyPhone,
      CompanyAddress: data?.companyAddress,
      Deposit: data?.deposit,
      RentDayCount: data?.dayRentCount ?? 1,
      OneDayPrice: data?.rentDayPrice ?? 55,
      IsPaid: isPaidChecked,
    }).then((res) => {
      if (res.Overlaps) {
        setOverLaps(true);
      } else {
        setOverLaps(false);
        back === 'history'
          ? history('/admin/managment/history/reservations')
          : back === 'availability'
          ? history('/admin/managment/check/availability')
          : history('/admin/managment/reservations');
      }
    });
  };

  return (
    <Page loading={false}>
      <Styled.FormWrap>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleEdit}
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange={false}
          innerRef={formRef}
          enableReinitialize
        >
          {({ errors, setFieldValue, values, handleChange }) => (
            <div>
              <Form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Styled.Form>
                  <Styled.FieldWrapper>
                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="person">Fizinis asmuo</label>
                        <div style={{ width: '50px' }}>
                          <Field
                            id="person"
                            name={'filtersGroup'}
                            style={errors.person ? onError : null}
                            value="Person"
                            checked={selectedOption === 'Person'}
                            onChange={(selected) => {
                              setSelectedOption(selected);
                            }}
                            component={Radio}
                          />
                        </div>
                      </Styled.FieldWrapper>

                      <Styled.FieldWrapper>
                        <label htmlFor="business">Juridinis asmuo</label>
                        <div style={{ width: '50px' }}>
                          <Field
                            id="business"
                            value="Business"
                            name={'filtersGroup'}
                            checked={selectedOption === 'Business'}
                            style={errors.business ? onError : null}
                            onChange={(selected) => {
                              setSelectedOption(selected);
                            }}
                            component={Radio}
                          />
                        </div>
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="amenities">Pasirinkti automobilį</label>
                        <Field
                          id="vehicle"
                          name="vehicle"
                          // defaultOptions={defaultAmenities}
                          style={errors.vehicle ? onError : null}
                          options={vehicles}
                          isMulti={false}
                          value={selectedVehicle}
                          // cacheOptions
                          onChange={(selected) => {
                            setSelectedVehicle(selected);
                            setFieldValue('vehicle', selected);
                          }}
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          component={Select}
                        />
                      </Styled.FieldWrapper>
                    </Styled.Row>
                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="companyName">
                          {selectedOption === 'Business'
                            ? 'Įmonės pavadinimas'
                            : 'Nuomininkas'}
                        </label>
                        <Field
                          id="companyName"
                          name="companyName"
                          style={errors.companyName ? onError : null}
                          placeholder={
                            selectedOption === 'Business'
                              ? 'Įvesti įmonės pavadinimą'
                              : 'Įvesti nuomininką'
                          }
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          onChange={(event) => {
                            setFieldValue('companyName', event.target.value);
                            setFieldValue('driverName', event.target.value);
                            setQuery(event.target.value);
                          }}
                          component={Input}
                        />
                        <ErrorMessage
                          name="companyName"
                          component={Styled.Error}
                        />
                        <Hero
                          options={reservationSuggestions}
                          selectedOption={selectedSuggestedOption}
                        />
                      </Styled.FieldWrapper>

                      {selectedOption === 'Business' && (
                        <Styled.FieldWrapper>
                          <label htmlFor="companyCode">Įmonės kodas</label>
                          <Field
                            id="companyCode"
                            name="companyCode"
                            style={errors.companyCode ? onError : null}
                            placeholder="Įvesti įmonės kodą"
                            component={Input}
                            disabled={
                              back === 'history' || back === 'availability'
                            }
                          />
                          <ErrorMessage
                            name="companyCode"
                            component={Styled.Error}
                          />
                        </Styled.FieldWrapper>
                      )}

                      {selectedOption === 'Business' && (
                        <Styled.FieldWrapper>
                          <label htmlFor="pvmCode">PVM kodas</label>
                          <Field
                            id="pvmCode"
                            name="pvmCode"
                            style={errors.pvmCode ? onError : null}
                            placeholder="Įvesti PVM kodą"
                            disabled={
                              back === 'history' || back === 'availability'
                            }
                            component={Input}
                          />
                          <ErrorMessage
                            name="pvmCode"
                            component={Styled.Error}
                          />
                        </Styled.FieldWrapper>
                      )}

                      <Styled.FieldWrapper>
                        <label htmlFor="companyAdditionalInfo">
                          Nuomininko papildoma informacija
                        </label>
                        <Field
                          id="companyAdditionalInfo"
                          name="companyAdditionalInfo"
                          style={errors.companyAdditionalInfo ? onError : null}
                          placeholder="Papildoma informacija"
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          component={Input}
                        />
                        <ErrorMessage
                          name="companyAdditionalInfo"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    {selectedOption === 'Business' && (
                      <Styled.Row>
                        <Styled.FieldWrapper>
                          <label htmlFor="companyPhone">
                            Įmonės telefono numeris
                          </label>
                          <Field
                            id="companyPhone"
                            name="companyPhone"
                            style={errors.companyPhone ? onError : null}
                            placeholder="Įmonės telefono numeris"
                            disabled={
                              back === 'history' || back === 'availability'
                            }
                            component={Input}
                          />
                          <ErrorMessage
                            name="companyPhone"
                            component={Styled.Error}
                          />
                        </Styled.FieldWrapper>

                        <Styled.FieldWrapper>
                          <label htmlFor="companyAddress">Įmonės adresas</label>
                          <Field
                            id="companyAddress"
                            name="companyAddress"
                            style={errors.companyAddress ? onError : null}
                            placeholder="Įmonės adresas"
                            disabled={
                              back === 'history' || back === 'availability'
                            }
                            component={Input}
                          />
                          <ErrorMessage
                            name="companyAddress"
                            component={Styled.Error}
                          />
                        </Styled.FieldWrapper>
                      </Styled.Row>
                    )}

                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="driverName">
                          Vairuotojo vardas, pavarde
                        </label>
                        <Field
                          id="driverName"
                          name="driverName"
                          style={errors.driverName ? onError : null}
                          placeholder="Įvesti vairuotojo vardą"
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          component={Input}
                        />
                        <ErrorMessage
                          name="driverName"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>
                      {/* <Styled.FieldWrapper>
                        <label htmlFor="driverSurname">
                          Vairuotojo pavardė
                        </label>
                        <Field
                          id="driverSurname"
                          name="driverSurname"
                          style={errors.driverSurname ? onError : null}
                          placeholder="Įvesti vairuotojo pavardę"
                          component={Input}
                        />
                        <ErrorMessage
                          name="driverSurname"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper> */}
                      <Styled.FieldWrapper>
                        <label htmlFor="driverPhoneNumber">
                          Vairuotojo telefono numeris
                        </label>
                        <Field
                          id="driverPhoneNumber"
                          name="driverPhoneNumber"
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          style={errors.driverPhoneNumber ? onError : null}
                          placeholder="Įvesti vairuotojo telefono numerį"
                          component={Input}
                        />
                        <ErrorMessage
                          name="driverPhoneNumber"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>
                      <Styled.FieldWrapper>
                        <label htmlFor="driverIdNumber">
                          Vairuotojo asmens kodas
                        </label>
                        <Field
                          id="driverIdNumber"
                          name="driverIdNumber"
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          style={errors.driverIdNumber ? onError : null}
                          placeholder="Įvesti asmens kodą"
                          component={Input}
                        />
                        <ErrorMessage
                          name="driverIdNumber"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="licenseNumber">
                          Vairuotojo teisių numeris
                        </label>
                        <Field
                          id="licenseNumber"
                          name="licenseNumber"
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          style={errors.licenseNumber ? onError : null}
                          placeholder="Įvesti vairuotojo teisių numerį"
                          component={Input}
                        />
                        <ErrorMessage
                          name="licenseNumber"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>

                      <Styled.FieldWrapper>
                        <label htmlFor="driverAdress">Vairuotojo adresas</label>
                        <Field
                          id="driverAdress"
                          name="driverAdress"
                          disabled={
                            back === 'history' || back === 'availability'
                          }
                          style={errors.driverAdress ? onError : null}
                          placeholder="Įvesti vairuotojo adresą"
                          component={Input}
                        />
                        <ErrorMessage
                          name="driverAdress"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="rentPrice">
                          Preliminari nuomos kaina
                        </label>
                        <Field
                          id="rentPrice"
                          name="rentPrice"
                          style={errors.rentPrice ? onError : null}
                          placeholder="Nuomos kaina"
                          component={Input}
                        />
                        <ErrorMessage
                          name="rentPrice"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>

                      <Styled.FieldWrapper>
                        <label htmlFor="deposit">Depozitas</label>
                        <Field
                          id="deposit"
                          name="deposit"
                          style={errors.deposit ? onError : null}
                          placeholder="Depozitas"
                          component={Input}
                        />
                        <ErrorMessage name="deposit" component={Styled.Error} />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="rentDayPrice">Paros kaina</label>
                        <Field
                          id="rentDayPrice"
                          name="rentDayPrice"
                          style={errors.rentPrice ? onError : null}
                          placeholder="Paros kaina"
                          onChange={(val) => {
                            setFieldValue(
                              'rentPrice',
                              values.dayRentCount * val.target.value,
                            );

                            setFieldValue('rentDayPrice', val.target.value);
                          }}
                          number
                          component={Input}
                        />
                        <ErrorMessage
                          name="rentPrice"
                          component={Styled.Error}
                        />
                      </Styled.FieldWrapper>

                      <Styled.FieldWrapper>
                        <label htmlFor="dayRentCount">Parų skaičius</label>
                        <Field
                          id="dayRentCount"
                          name="dayRentCount"
                          style={errors.depdayRentCountosit ? onError : null}
                          placeholder="dayRentCount"
                          onChange={(val) => {
                            setFieldValue(
                              'rentPrice',
                              values.rentDayPrice * val.target.value,
                            );

                            setFieldValue('dayRentCount', val.target.value);
                          }}
                          number
                          component={Input}
                        />
                        <ErrorMessage name="deposit" component={Styled.Error} />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CustomCalendar
                        defaultStart={reservation?.ReservationFrom}
                        defaultEnd={reservation?.ReservationTo}
                        selectedDateRange={(date) => dateChanged(date)}
                      ></CustomCalendar>
                    </Styled.Row>

                    <Styled.Row
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        marginTop: '5px',
                      }}
                    >
                      <div style={{ width: '50px' }}>
                        <Checkbox
                          name="isPaid"
                          value="true"
                          checked={isPaidChecked}
                          onChange={(e) => {
                            handleChange(e);
                            setIsPaidChecked(!isPaidChecked);
                          }}
                          error={errors.agreement2}
                        >
                          Sumokėta
                        </Checkbox>
                      </div>
                    </Styled.Row>
                  </Styled.FieldWrapper>
                </Styled.Form>
                {overLaps && (
                  <div
                    style={{
                      height: '85px',
                      backgroundColor: 'red',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '36px',
                      fontWeight: 'bold',
                      display: 'flex',
                    }}
                  >
                    AUTOMOBILIS UŽIMTAS, PATIKRINKITE LAIKUS
                  </div>
                )}
                <Styled.ButtonWrap>
                  <Styled.Button type="submit">
                    {/* <Styled.Button type="submit" disabled={submitted}> */}
                    Išsaugoti
                  </Styled.Button>
                  <Styled.Button
                    type="reset"
                    onClick={() =>
                      back === 'history'
                        ? history('/admin/managment/history/reservations')
                        : back === 'availability'
                        ? history('/admin/managment/check/availability')
                        : history('/admin/managment/reservations')
                    }
                  >
                    Grįžti
                  </Styled.Button>
                </Styled.ButtonWrap>
              </Form>
            </div>
          )}
        </Formik>
      </Styled.FormWrap>
    </Page>
  );
};
export default ReserveVehicle;
