import styled from 'styled-components/macro';

const Styled = {
  Form: styled.div`
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,

  FromBackground: styled.div`
    background-color: lightgrey;
    width: 550px;
    display: flex;
    justify-content: center;
  `,

  FieldWrapper: styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    label:not(:first-child) {
      margin-top: 15px;
    }
  `,
  Error: styled.div`
    word-wrap: break-word;
    color: white;
    background-color: red;
    border-radius: 6px;
  `,

  FormWrap: styled.div`
    height: calc(100vh - 210px);
    width: 100%;
    span {
      display: flex;
      justify-content: center;
    }
  `,
  Link: styled.a`
    cursor: pointer;
    text-decoration: underline;
    padding-left: 5px;
  `,
};

export default Styled;
