import React from 'react';
import styled from 'styled-components';
// Components
import ClientSlider from '../Elements/ClientSlider';
import ServiceBox from '../Elements/ServiceBox';
import FullButton from '../Buttons/FullButton';
// Assets
import AddImage1 from '../../../assets/img/add/1.png';
import AddImage2 from '../../../assets/img/add/2.png';
import AddImage3 from '../../../assets/img/add/3.png';
import AddImage4 from '../../../assets/img/add/4.png';

export default function Services() {}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
