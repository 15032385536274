import React from 'react';
import Styled from './Page.styles';
import Loader from '../Loader/Loader';
const Page = ({ children, loading }) => {
  return (
    <Styled.Page isLoading={loading}>
      {loading && <Loader></Loader>}
      {!loading && children}
    </Styled.Page>
  );
};

export default Page;
