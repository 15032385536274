import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useState } from 'react';

const CustomCalendarV2 = (props) => {
  //   const [selectedRange, setSelectedRange] = useState((state) => {
  //     const hours = new Date();
  //     hours.setHours(10, 0, 0, 0);
  //     hours.setUTCHours(10, 0, 0, 0);
  //     return {
  //       startDate: props.defaultStart
  //         ? props.defaultStart
  //         : hours.toISOString().substring(0, 16),
  //       endDate: props.defaultEnd
  //         ? props.defaultEnd
  //         : hours.toISOString().substring(0, 16),
  //     };
  //   });

  const [selectedRange, setSelectedRange] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  const handleSelection = (type, date) => {
    let dateTimeObj = {
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate,
    };

    if (type === 'from') {
      dateTimeObj.startDate = date.target.value;
    } else if (type === 'to') {
      dateTimeObj.endDate = date.target.value;
    }

    setSelectedRange({
      startDate: dateTimeObj.startDate,
      endDate: dateTimeObj.endDate,
    });

    props.selectedDateRange(dateTimeObj);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '15px',
            }}
          >
            <span> Nuo </span>
            <input
              type={'date'}
              value={selectedRange.startDate}
              onChange={(date) => {
                console.log(date.target.value);
                handleSelection('from', date);
              }}
            ></input>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '15px',
            }}
          >
            <span> Iki</span>
            <input
              type={'date'}
              value={selectedRange.endDate}
              onChange={(date) => {
                handleSelection('to', date);
              }}
            ></input>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomCalendarV2;
