import React from 'react';
import Styled from './Input.styles';

const Input = ({
  label,
  labelStyle,
  errorStyle,
  error,
  textarea,
  password,
  field,
  file,
  form,
  number,
  primaryColor,
  ...rest
}) => {
  return (
    <Styled.TextField error={false}>
      {textarea && !password && !number && (
        <Styled.Textarea rows={6} {...field} {...rest} />
      )}
      {!textarea && !password && !number && (
        <Styled.Input type="text" {...field} {...rest} />
      )}
      {password && <Styled.Input type="password" {...field} {...rest} />}
      {number && <Styled.Input type="number" {...field} {...rest} />}
      {file && <Styled.Input type="file" {...field} {...rest} />}
    </Styled.TextField>
  );
};

export default Input;
