import { Formik, Form, Field, ErrorMessage } from 'formik';
import useFetch from 'use-http';
import * as Yup from 'yup';
import Styled from '../Form.styles';
import { useNavigate, useLocation } from 'react-router';
import Input from '../../../../shared/Input/Input';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useRef } from 'react';
import Select from 'react-select';
import Page from '../../../../shared/Page/Page';

const ManageAppliedJob = () => {
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [date, setDate] = useState();
  const { post: manageJob } = useFetch('/Vehicles/ManageAppliedJob');
  const history = useNavigate();
  const location = useLocation();

  const job = location?.state?.job;

  const { get: vehicleOptions } = useFetch('/Vehicles/GetVehiclesOptions/true');
  const formRef = useRef();

  useEffect(() => {
    if (job) {
      setDate(job.CreatedAt.substring(0, 10));
      setSelectedVehicle({
        value: job.Vehicle.Id,
        label: job.Vehicle.Plate,
      });
      formRef.current.values.vehicle = {
        value: job.Vehicle.Id,
        label: job.Vehicle.Plate,
      };
    }
  }, [job]);

  useEffectOnce(() => {
    getVehicleOptions();
  });

  const getVehicleOptions = async () => {
    const response = await vehicleOptions();
    if (response) {
      const tmp = [];
      response.forEach((d) => {
        tmp.push({ value: d.Value, label: d.Label });
      });
      setVehicles(tmp);
    }
  };

  const initialValues = {
    name: job?.Name,
    km: job?.Km,
  };

  const validationSchema = Yup.object().shape({});

  const onError = {
    color: 'red',
    border: '2px solid #d66',
  };

  const handleEdit = async (data) => {
    await manageJob('', {
      JobId: job?.Id,
      VehicleId: data.vehicle.value,
      Name: data.name,
      Km: data.km,
      Date: date,
    });
    history('/admin/managment/jobs/applied');
  };

  return (
    <Page loading={false}>
      <Styled.FormWrap>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleEdit}
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange={false}
          innerRef={formRef}
        >
          {({ errors, setFieldValue }) => (
            <div>
              <Form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Styled.Form>
                  <Styled.FieldWrapper>
                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="amenities">Pasirinkti automobilį</label>
                        <div style={{ width: '415px' }}>
                          <Field
                            id="vehicle"
                            name="vehicle"
                            // defaultOptions={defaultAmenities}
                            style={errors.vehicle ? onError : null}
                            options={vehicles}
                            isMulti={false}
                            value={selectedVehicle}
                            // cacheOptions
                            onChange={(selected) => {
                              setSelectedVehicle(selected);
                              setFieldValue('vehicle', selected);
                            }}
                            component={Select}
                          />
                        </div>
                      </Styled.FieldWrapper>
                    </Styled.Row>
                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="companyAdditionalInfo">Darbas</label>
                        <Field
                          id="name"
                          name="name"
                          style={errors.name ? onError : null}
                          placeholder="Darbas"
                          component={Input}
                        />
                        <ErrorMessage name="name" component={Styled.Error} />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row>
                      <Styled.FieldWrapper>
                        <label htmlFor="km">Kilometrai</label>
                        <Field
                          id="km"
                          name="km"
                          style={errors.km ? onError : null}
                          placeholder="Kilometrai"
                          component={Input}
                        />
                        <ErrorMessage name="km" component={Styled.Error} />
                      </Styled.FieldWrapper>
                    </Styled.Row>

                    <Styled.Row style={{ justifyContent: 'center' }}>
                      <span>Data</span>
                      <input
                        type="date"
                        value={date}
                        onChange={(date) => setDate(date.target.value)}
                      ></input>
                    </Styled.Row>
                  </Styled.FieldWrapper>
                </Styled.Form>
                <Styled.ButtonWrap>
                  <Styled.Button type="submit">Išsaugoti</Styled.Button>

                  <Styled.Button type="reset" onClick={() => history(-1)}>
                    Grįžti
                  </Styled.Button>
                </Styled.ButtonWrap>
              </Form>
            </div>
          )}
        </Formik>
      </Styled.FormWrap>
    </Page>
  );
};
export default ManageAppliedJob;
