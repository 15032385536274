import React from 'react';
import styled from 'styled-components';
// Assets
import ContactImg1 from '../../../assets/img/contact-1.png';
import ContactImg2 from '../../../assets/img/contact-2.png';
import ContactImg3 from '../../../assets/img/contact-3.png';

export default function Contact() {}

const Wrapper = styled.section`
  width: 100%;
`;
