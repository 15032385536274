import { useNavigate, useLocation } from 'react-router-dom';
import './styles.css';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import styled from 'styled-components';

import TopNavbar from '../components/shared/Nav/TopNavbar';
import Header from '../components/shared/Sections/Header';
import Services from '../components/shared/Sections/Services';
import Main from '../components/shared/Sections/MainPage';
import Pricing from '../components/shared/Sections/Pricing';
import Contact from '../components/shared/Sections/Contact';
import Footer from '../components/shared/Sections/Footer';

export default function Landing() {
  const history = useNavigate();
  const location = useLocation();

  return (
    <Page>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </Page>
  );
}

const Page = styled.div`
  min-width: 250px;
`;
