import styled from 'styled-components/macro';

const Form = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 15px;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label:not(:first-child) {
    margin-top: 15px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  label {
    display: flex;
    flex-direction: column;
  }
  label:not(:first-child) {
    margin-top: 15px;
  }

  ${FieldWrapper}:not(:first-child) {
    margin-left: 25px;
  }
`;

const Error = styled.div`
  word-wrap: break-word;
  color: white;
  background-color: red;
  border-radius: 3px;
`;

const FormWrap = styled.div`
  height: calc(100vh - 210px);
  width: 100%;
  span {
    display: flex;
    justify-content: center;
    a {
      padding-left: 5px;
      text-decoration: underline;
    }
  }
`;

const Test = styled.div``;

const Styled = {
  Test,
  Form,
  Error,
  FormWrap,
  Row,
  FieldWrapper,
};
export default Styled;
