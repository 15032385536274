import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Assets
import LogoImg from '../../../assets/svg/Logo';
import { AiFillFacebook } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import { MdMail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';
export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <>
      <Wrapper>
        <Content>
          <Items>
            <Icons>
              <div>
                <Link to="main" smooth={true}>
                  <Logo>
                    <img src="/img/idRataiSmall.jpg" alt="img" />
                  </Logo>
                </Link>
                <IconsWrap>
                  <a href="https://www.facebook.com/IDRatai" target="_blank">
                    <AiFillFacebook></AiFillFacebook>
                  </a>

                  <a
                    href="https://www.instagram.com/mb_idratai/"
                    target="_blank"
                  >
                    {' '}
                    <AiFillInstagram> </AiFillInstagram>
                  </a>
                  <a href="mailto: info@idratai.lt">
                    <MdMail></MdMail>
                  </a>
                </IconsWrap>
              </div>
            </Icons>
          </Items>

          <Info style={{ fontWeight: 'bold' }}>
            <span>ID Ratai, MB</span>
            <span>Savanorių pr. 136A, Vilnius</span>
            <span>Darbo laikas 24/7</span>
          </Info>

          <Info style={{ color: 'red', fontWeight: 'bold' }}>
            <span>
              {' '}
              <MdMail style={{ marginRight: '18px' }}></MdMail>info@idratai.lt
            </span>
            <span style={{ color: 'white' }}>
              {' '}
              <AiFillPhone style={{ marginRight: '12px' }}></AiFillPhone><a style={{ color: "red" }} href="tel:+37063663594">  +370 636 63594</a>
            </span>
            <span style={{ color: 'white' }}>
              <AiFillPhone style={{ marginRight: '12px' }}></AiFillPhone>
              <a style={{ color: "red" }} href="tel:+37063663596">  +370 636 63596</a>
            </span>
          </Info>
        </Content>
      </Wrapper>
      <div style={{ marginLeft: '20%', marginBottom: '30px' }}>
        All rights reserved © IDRATAI
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  border-top: 2px solid black;
  display: flex;
  flex-direction: row;
  margin-bottom: 45px;
`;

const IconsWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  cursor: pointer;
  *:hover {
    color: red;
  }
`;

const Items = styled.div`
  display: flex;
  min-height: 100px;

  flex-direction: row;
  align-items: center;
  svg {
    min-height: 50px;
    min-width: 50px;
    line-height: 40px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Logo = styled.div`
  img {
    height: 80px;
    width: 220px;
  }
  cursor: pointer;
`;
