import DataTable from 'react-data-table-component';
import Loader from '../../../../shared/Loader/Loader.js';
import React from 'react';
import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import { useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import { FaFileInvoice } from 'react-icons/fa';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import { useNavigate } from 'react-router';
import Button from '../../../../shared/Button/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Reservations = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState();
  const { get: getReservations, response: getReservationsResponse } = useFetch(
    '/Reservation/GetActiveReservations',
  );

  const { delete: removeReservation } = useFetch(
    '/Reservation/RemoveReservation',
  );

  const { get: getInvoice } = useFetch('/Invoices/GenerateInvoice');

  const [Reservations, setReservations] = useState();

  useEffectOnce(() => {
    getData();
  });

  const getData = async () => {
    setLoading(true);
    const response = await getReservations();
    if (getReservationsResponse.status > 200) {
    } else {
      setReservations(response);
      setLoading(false);
    }
  };

  async function handleDelete(id) {
    await removeReservation(id);

    setReservations((current) =>
      current.filter((obj) => {
        return obj.Id !== id;
      }),
    );
  }

  const showDialog = (id) => {
    console.log(id);
    confirmAlert({
      title: 'Patvirinti ištrynimą',
      message: 'Ar tikrai norite ištrinti rezervaciją?',
      buttons: [
        {
          label: 'Taip',
          onClick: () => handleDelete(id),
        },
        {
          label: 'Ne',
        },
      ],
    });
  };

  const generateInvoice = async (id) => {
    const invoice = await getInvoice(id);

    var a = document.createElement('a'); //Create <a>
    a.download = invoice.Filename;
    a.href = invoice.File;
    a.click();
  };

  const conditionalRowStyles = [
    {
      when: (row) => !row?.Vehicle?.Active,
      style: {
        backgroundColor: 'red',
      },
    },
    {
      when: (row) => !row?.IsPaid,
      style: {
        backgroundColor: 'Crimson',
      },
    },
  ];

  const columns = [
    {
      id: 1,
      name: 'Valstybinis numeris',
      selector: (row) => row?.Vehicle?.Plate,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'Nuomuotojas',
      selector: (row) => row.CompanyName,
      sortable: true,
      reorder: true,
    },

    {
      id: 4,
      name: 'Išnuomuota nuo',
      selector: (row) =>
        new Date(row.ReservationFrom).toISOString().slice(0, 10),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: 'Išnuomuota iki',
      selector: (row) => new Date(row.ReservationTo).toISOString().slice(0, 10),
      sortable: true,
      reorder: true,
    },

    {
      id: 6,
      cell: (row) => (
        <AiFillEdit
          onClick={() =>
            history('/admin/managment/reservations/create', {
              state: {
                data: row,
                from: 'active',
              },
            })
          }
        />
      ),
      width: '125px',
    },
    {
      id: 7,
      cell: (row) => (
        <FaFileInvoice
          onClick={() => {
            generateInvoice(row.Id);
          }}
        />
      ),
      width: '125px',
    },
    {
      id: 8,
      cell: (row) => (
        <IoTrashOutline
          onClick={() => {
            showDialog(row.Id);
          }}
        />
      ),
      width: '125px',
    },
  ];

  return (
    <Styled.ContentWrapper>
      <Sidebar></Sidebar>
      {(loading && (
        <div>
          <Loader></Loader>
        </div>
      )) || (
        <div style={{ width: '100%' }}>
          <Styled.Table>
            <DataTable
              title="Aktyvios rezervacijos"
              columns={columns}
              data={Reservations}
              conditionalRowStyles={conditionalRowStyles}
              defaultSortFieldId={5}
              paginationPerPage={20}
              // sortIcon={<SortIcon />}
              pagination
            />
          </Styled.Table>
          <Button
            onClick={() => history('/admin/managment/reservations/create')}
            text="Rezervuoti"
          ></Button>
        </div>
      )}
    </Styled.ContentWrapper>
  );
};
export default Reservations;
