import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Styled = {
  Page: styled.div`
    position: relative;
    height: 100%;
    padding-bottom: 80px;
    min-height: 100%;

    ${mediaDown('md')`
    padding-bottom: 70px;
    min-height: calc(100% - 115px);
  `}
  `,
};

export default Styled;
