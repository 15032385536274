import { Formik, Form, Field, ErrorMessage } from 'formik';
import useFetch from 'use-http';
import * as Yup from 'yup';
import Styled from '../Form.styles';
import { useNavigate, useLocation } from 'react-router';
import Input from '../../../../shared/Input/Input';
import { useEffect, useState } from 'react';

const AddVehicle = () => {
  const { post: manageVehicle } = useFetch('/Vehicles/ManageVehicle');
  const [techDate, setTechDate] = useState();
  const [insuranceDate, setInsuranceDate] = useState();
  const [kaskoDate, setKaskoDate] = useState();
  const history = useNavigate();
  const location = useLocation();

  const vehicle = location?.state?.data;

  useEffect(() => {
    if (vehicle) {
      setInsuranceDate(vehicle.InsuranceDate);
      setTechDate(vehicle.TechDate);
      setKaskoDate(vehicle.Kasko);
    }
  }, [vehicle]);

  const initialValues = {
    plate: vehicle?.Plate,
    vin: vehicle?.Vin,
    model: vehicle?.Model,
    make: vehicle?.Make,
    year: vehicle?.Year,
    color: vehicle?.Color,
    seats: vehicle?.Seats,
    marketPrice: vehicle?.MarketPrice,
    oneToFivePrice: vehicle?.OneToFivePrice,
    sixToFifteenPrice: vehicle?.SixToFifteenPrice,
    fromSixteenPrice: vehicle?.FromSixteenPrice,
    monthPrice: vehicle?.MonthPrice,
    kasko: vehicle?.Kasko,
  };

  const validationSchema = Yup.object().shape({
    plate: Yup.string().required('Name must not be empty'),
    vin: Yup.string().required('Name must not be empty'),
    make: Yup.string().required('Name must not be empty'),
    model: Yup.string().required('Name must not be empty'),
    year: Yup.string().required('Name must not be empty'),
    color: Yup.string().required('Name must not be empty'),
    seats: Yup.string().required('Name must not be empty'),
    marketPrice: Yup.string().required('Name must not be empty'),
  });

  const onError = {
    color: 'red',
    border: '2px solid #d66',
  };

  const handleEdit = async (data) => {
    await manageVehicle('', {
      isReservation: false,
      id: vehicle?.Id,
      plate: data.plate,
      vin: data.vin,
      model: data.model,
      make: data.make,
      year: data.year,
      color: data.color,
      seats: data.seats,
      marketPrice: data.marketPrice,
      oneToFivePrice: data.oneToFivePrice,
      sixToFifteenPrice: data.sixToFifteenPrice,
      fromSixteenPrice: data.fromSixteenPrice,
      monthPrice: data.monthPrice,
      techDate: techDate,
      insuranceDate: insuranceDate,
      kasko: kaskoDate,
    });
    history('/admin/managment/vehicles');
  };

  return (
    <Styled.FormWrap>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleEdit}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
      >
        {({ errors }) => (
          <div>
            <Form
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Styled.Form>
                <Styled.FieldWrapper>
                  <Styled.Row>
                    <Styled.FieldWrapper>
                      <label htmlFor="name">Valstybinis numeris</label>
                      <Field
                        id="plate"
                        name="plate"
                        style={errors.plate ? onError : null}
                        placeholder="Valstybinis numeris"
                        component={Input}
                      />
                      <ErrorMessage name="plate" component={Styled.Error} />
                    </Styled.FieldWrapper>
                    <Styled.FieldWrapper>
                      <label htmlFor="vin">VIN kodas</label>
                      <Field
                        id="vin"
                        name="vin"
                        style={errors.vin ? onError : null}
                        placeholder="VIN"
                        component={Input}
                      />
                      <ErrorMessage name="vin" component={Styled.Error} />
                    </Styled.FieldWrapper>
                  </Styled.Row>

                  <Styled.Row>
                    <Styled.FieldWrapper>
                      <label htmlFor="make">Gamintojas</label>
                      <Field
                        id="make"
                        name="make"
                        style={errors.make ? onError : null}
                        placeholder="Gamintojas"
                        component={Input}
                      />
                      <ErrorMessage name="make" component={Styled.Error} />
                    </Styled.FieldWrapper>
                    <Styled.FieldWrapper>
                      <label htmlFor="model">Modelis</label>
                      <Field
                        id="model"
                        name="model"
                        style={errors.model ? onError : null}
                        placeholder="Modelis"
                        component={Input}
                      />
                      <ErrorMessage name="model" component={Styled.Error} />
                    </Styled.FieldWrapper>
                  </Styled.Row>

                  <Styled.Row>
                    <Styled.FieldWrapper>
                      <label htmlFor="year">Metai</label>
                      <Field
                        id="year"
                        name="year"
                        style={errors.year ? onError : null}
                        placeholder="Metai"
                        component={Input}
                      />
                      <ErrorMessage name="year" component={Styled.Error} />
                    </Styled.FieldWrapper>
                    <Styled.FieldWrapper>
                      <label htmlFor="color">Spalva</label>
                      <Field
                        id="color"
                        name="color"
                        style={errors.color ? onError : null}
                        placeholder="Spalva"
                        component={Input}
                      />
                      <ErrorMessage name="color" component={Styled.Error} />
                    </Styled.FieldWrapper>
                  </Styled.Row>

                  <Styled.Row>
                    <Styled.FieldWrapper>
                      <label htmlFor="seats">Sėdimos vietos</label>
                      <Field
                        id="seats"
                        name="seats"
                        style={errors.seats ? onError : null}
                        placeholder="Sėdimos vietos"
                        component={Input}
                      />
                      <ErrorMessage name="seats" component={Styled.Error} />
                    </Styled.FieldWrapper>
                    <Styled.FieldWrapper>
                      <label htmlFor="marketPrice">Rinkos kaina</label>
                      <Field
                        id="marketPrice"
                        name="marketPrice"
                        style={errors.marketPrice ? onError : null}
                        placeholder="Rinkos kaina"
                        component={Input}
                      />
                      <ErrorMessage
                        name="marketPrice"
                        component={Styled.Error}
                      />
                    </Styled.FieldWrapper>
                  </Styled.Row>
                  {/* <Styled.Row>
                    <Styled.FieldWrapper>
                      <label htmlFor="marketPrice">1-5d.d kaina</label>
                      <Field
                        id="oneToFivePrice"
                        name="oneToFivePrice"
                        style={errors.oneToFivePrice ? onError : null}
                        placeholder=""
                        component={Input}
                      />
                      <ErrorMessage
                        name="oneToFivePrice"
                        component={Styled.Error}
                      />
                    </Styled.FieldWrapper>

                    <Styled.FieldWrapper>
                      <label htmlFor="marketPrice">6-15d. kaina</label>
                      <Field
                        id="sixToFifteenPrice"
                        name="sixToFifteenPrice"
                        style={errors.sixToFifteenPrice ? onError : null}
                        placeholder=""
                        component={Input}
                      />
                      <ErrorMessage
                        name="sixToFifteenPrice"
                        component={Styled.Error}
                      />
                    </Styled.FieldWrapper>

                    <Styled.FieldWrapper>
                      <label htmlFor="marketPrice">nuo 16d. kaina</label>
                      <Field
                        id="fromSixteenPrice"
                        name="fromSixteenPrice"
                        style={errors.fromSixteenPrice ? onError : null}
                        placeholder=""
                        component={Input}
                      />
                      <ErrorMessage
                        name="fromSixteenPrice"
                        component={Styled.Error}
                      />
                    </Styled.FieldWrapper>

                    <Styled.FieldWrapper>
                      <label htmlFor="monthPrice">Menesio kaina</label>
                      <Field
                        id="monthPrice"
                        name="monthPrice"
                        style={errors.monthPrice ? onError : null}
                        placeholder=""
                        component={Input}
                      />
                      <ErrorMessage
                        name="monthPrice"
                        component={Styled.Error}
                      />
                    </Styled.FieldWrapper>
                  </Styled.Row> */}
                  <Styled.Row style={{ justifyContent: 'center' }}>
                    <span>Draudimas iki:</span>
                    <input
                      type="date"
                      value={insuranceDate}
                      onChange={(date) => setInsuranceDate(date.target.value)}
                    ></input>

                    <span>Technikinė iki:</span>
                    <input
                      type="date"
                      value={techDate}
                      onChange={(date) => setTechDate(date.target.value)}
                    ></input>

                    <span>Kasko iki:</span>
                    <input
                      type="date"
                      value={kaskoDate}
                      onChange={(date) => setKaskoDate(date.target.value)}
                    ></input>
                  </Styled.Row>
                </Styled.FieldWrapper>
              </Styled.Form>

              <Styled.ButtonWrap>
                <Styled.Button type="submit">Išsaugoti</Styled.Button>

                <Styled.Button type="reset" onClick={() => history(-1)}>
                  Grįžti
                </Styled.Button>
              </Styled.ButtonWrap>
            </Form>
          </div>
        )}
      </Formik>
    </Styled.FormWrap>
  );
};
export default AddVehicle;
