import DataTable from 'react-data-table-component';
import React from 'react';
import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import { useState } from 'react';
import { FcCheckmark } from 'react-icons/fc';
// IoCloseSharp;
import { IoCloseSharp, IoTrashOutline } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import { useNavigate } from 'react-router';
import Button from '../../../../shared/Button/Button';
import Loader from '../../../../shared/Loader/Loader.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Jobs = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState();

  const { get: getJobs, response: getJobsResponse } =
    useFetch('/Vehicles/GetJobs');

  const { delete: removeJob } = useFetch('/Vehicles/RemoveJob');

  const [jobs, setJobs] = useState();

  useEffectOnce(() => {
    getJobsFn();
  });

  const getJobsFn = async () => {
    setLoading(true);
    const response = await getJobs();
    if (getJobsResponse.status > 200) {
    } else {
      setJobs(response);
      setLoading(false);
    }
  };

  const showDialog = (id) => {
    confirmAlert({
      title: 'Patvirinti ištrynimą',
      message: 'Ar tikrai norite ištrinti darbą?',
      buttons: [
        {
          label: 'Taip',
          onClick: () => handleDelete(id),
        },
        {
          label: 'Ne',
        },
      ],
    });
  };

  async function handleDelete(id) {
    await removeJob(id);

    setJobs((current) =>
      current.filter((obj) => {
        return obj.Id !== id;
      }),
    );
  }

  const columns = [
    {
      id: 1,
      name: 'Valst. Nr',
      selector: (row) => row.Vehicle?.Plate,
      sortable: true,
      reorder: true,
      width: '10%',
    },

    {
      id: 2,
      name: 'Darbas',
      selector: (row) => row.Name,
      sortable: true,
      reorder: true,
      width: '700px',
    },

    {
      //   cell: (row) => <FaTrash onClick={() => HandleRemove(row.Id)} />,
    },

    //  <FcCheckmark
    //       onClick={() =>
    //         history('/admin/managment/vehicles/create', { data: row })
    //       }
    //     />
    {
      cell: (row) => (
        <AiFillEdit
          onClick={() =>
            history('/admin/managment/jobs/create', { state: { job: row } })
          }
        />
      ),
    },
    {
      id: 7,
      cell: (row) => (
        <IoTrashOutline
          onClick={() => {
            // handleDelete(row.Id);
            showDialog(row.Id);
          }}
        />
      ),
      width: '125px',
    },
  ];

  return (
    <Styled.ContentWrapper>
      <Sidebar></Sidebar>
      {(loading && (
        <div>
          <Loader></Loader>
        </div>
      )) || (
        <div style={{ width: '100%' }}>
          <Styled.Table>
            <DataTable
              title="Darbai"
              columns={columns}
              data={jobs}
              defaultSortFieldId={1}
              // sortIcon={<SortIcon />}
              pagination
            />
          </Styled.Table>
          <Button
            onClick={() => history('/admin/managment/jobs/create')}
            text="Pridėti naują darbą"
          ></Button>
        </div>
      )}
    </Styled.ContentWrapper>
  );
};
export default Jobs;
