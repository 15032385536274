import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Components
import Sidebar from './Sidebar';
import Backdrop from '../Elements/Backdrop';
// Assets
import LogoIcon from '../../../assets/svg/Logo';
import BurgerIcon from '../../../assets/svg/BurgerIcon';
import { useNavigate } from 'react-router';
import {
  useUserDispatch,
  useUserState,
} from '../../context/UserContext/UserContext';

export default function TopNavbar() {
  const userState = useUserState();
  let history = useNavigate();
  const sessionDispatch = useUserDispatch();

  const handleLogout = () => {
    sessionDispatch({ type: 'LOGOUT' });
    history('/');
  };

  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: '60px' } : { height: '80px' }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoIcon />
            <h1 style={{ marginLeft: '15px' }} className="font20 extraBold">
              ID Ratai
            </h1>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Pradžia
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Privalumai
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="projects"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Nuoma
              </Link>
            </li>

            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Kontaktai
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {!userState.IsLoggedIn && (
              <li
                className="semiBold font15 pointer"
                onClick={() => {
                  history('/login');
                }}
              >
                <a style={{ padding: '10px 30px 10px 0' }}>Log in</a>
              </li>
            )}

            {userState.IsLoggedIn && (
              <>
                <li
                  className="semiBold font15 pointer"
                  onClick={() => {
                    history('/admin/managment/vehicles');
                  }}
                >
                  <a style={{ padding: '10px 30px 10px 0' }}>
                    {userState.Username}
                  </a>
                </li>

                <li
                  className="semiBold font15 pointer flexCenter"
                  onClick={handleLogout}
                >
                  <a
                    className="radius8 lightBg"
                    style={{ padding: '10px 15px' }}
                  >
                    Atsijungti
                  </a>
                </li>
              </>
            )}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
