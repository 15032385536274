import DataTable from 'react-data-table-component';
import Loader from '../../../../shared/Loader/Loader.js';
import React, { useEffect } from 'react';
import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import { useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaFileInvoice } from 'react-icons/fa';
import { BiRefresh } from 'react-icons/bi';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import { useNavigate } from 'react-router';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import CustomCalendarV2 from '../../../../shared/Calendar/CustomCalendarV2.js';
import { FcCheckmark } from 'react-icons/fc';
import { IoCloseSharp } from 'react-icons/io5';

const Reservations = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [newPrice, setNewPrice] = useState();
  const [updatePrice, setUpdatePrice] = useState();
  const [vehicles, setVehicles] = useState();
  const [activeFilter, setActiveFilter] = useState(null);
  const [rowPrices, setRowPrices] = useState([{ id: null, price: 0 }]);
  const [selectedRange, setSelectedRange] = useState({
    startDate: undefined,
    endDate: undefined,
  });
  const [selectedVehicle, setSelectedVehicle] = useState();
  const { get: getReservations, response: getReservationsResponse } = useFetch(
    '/Reservation/GetReservationHistory',
  );

  const { get: vehicleOptions } = useFetch('/Vehicles/GetVehiclesOptions/true');

  const { delete: removeReservation } = useFetch(
    '/Reservation/RemoveReservation',
  );

  const { get: changeReservationPriceRequest } = useFetch(
    '/Reservation/ChangeReservationPrice',
  );

  const { get: getInvoice } = useFetch('/Invoices/GenerateInvoice');

  const { post: changeStatus } = useFetch('/Reservation/ChangePaymentStatus');

  const [Reservations, setReservations] = useState();

  useEffectOnce(() => {
    getReservationsFn();
  });

  const getReservationsFn = async () => {
    setLoading(true);
    const response = await getReservations();
    if (getReservationsResponse.status > 200) {
    } else {
      setReservations(response);

      const prices = [];
      response.forEach((res) => {
        prices.push({ id: res.Id, price: res.RentPrice });
      });

      setRowPrices(prices);
      setLoading(false);
    }
  };

  useEffectOnce(() => {
    getVehicleOptions();
  });

  const getVehicleOptions = async () => {
    const response = await vehicleOptions();
    if (response) {
      const tmp = [];
      tmp.push({ value: null, label: 'Visi automobiliai' });
      response.forEach((d) => {
        tmp.push({ value: d.Value, label: d.Label });
      });
      setVehicles(tmp);
    }
  };

  async function handleDelete(id) {
    await removeReservation(id);

    setReservations((current) =>
      current.filter((obj) => {
        return obj.Id !== id;
      }),
    );
  }

  const showDialog = (id) => {
    confirmAlert({
      title: 'Patvirinti ištrynimą',
      message: 'Ar tikrai norite ištrinti rezervaciją?',
      buttons: [
        {
          label: 'Taip',
          onClick: () => handleDelete(id),
        },
        {
          label: 'Ne',
        },
      ],
    });
  };

  const generateInvoice = async (id) => {
    const invoice = await getInvoice(id);

    var a = document.createElement('a'); //Create <a>
    a.download = invoice.Filename;
    a.href = invoice.File;
    a.click();
  };

  useEffect(() => {
    if (newPrice) {
      setSaving({ id: newPrice.id, saving: true });
      setSaved({ id: newPrice.id, saved: false });
      const timeOutId = setTimeout(() => setUpdatePrice(newPrice), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [newPrice]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    updatePriceFn();
  }, [updatePrice]);

  const updatePriceFn = async () => {
    if (updatePrice) {
      setSaving({ id: updatePrice.id, saving: true });
      await changeReservationPriceRequest(
        `?Id=${updatePrice.id}&price=${updatePrice.price}`,
      ).then((res) => {
        setSaving({ id: updatePrice.id, saving: false });
        setSaved({ id: updatePrice.id, saved: true });
      });
    }
  };

  //   const conditionalRowStyles = [
  //     {
  //       when: (row) => !row.Vehicle.Active,
  //       style: {
  //         backgroundColor: 'red',
  //       },
  //     },
  //   ];

  async function changeVehicleStatus(id) {
    await changeStatus('', {
      Id: id,
    });

    updateState(id);
  }

  const updateState = (id) => {
    const newState = Reservations.map((obj) => {
      // 👇️ if id equals 2, update country property
      if (obj.Id === id) {
        return { ...obj, IsPaid: !obj.IsPaid };
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setReservations(newState);
  };

  const columns = [
    {
      id: 1,
      name: 'Valstybinis numeris',
      selector: (row) => row.Vehicle?.Plate,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'Nuomuotojas',
      selector: (row) => row.CompanyName,
      sortable: true,
      reorder: true,
    },

    {
      id: 4,
      name: 'Išnuomuota nuo',
      selector: (row) =>
        new Date(row.ReservationFrom).toISOString().slice(0, 10),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: 'Išnuomuota iki',
      selector: (row) => new Date(row.ReservationTo).toISOString().slice(0, 10),
      sortable: true,
      reorder: true,
    },

    {
      id: 15,
      name: 'Kaina',
      selector: (row) => (
        <>
          <input
            type={'number'}
            value={rowPrices.filter((price) => price.id === row.Id)[0].price}
            onChange={(e) => {
              rowPrices.filter((price) => price.id === row.Id)[0].price =
                e.target.value;
              setNewPrice({ price: e.target.value, id: row.Id });
            }}
          ></input>
          {/* <FaSave
            onClick={savePrice(row.Id)}
            style={{ marginLeft: '10px' }}
          ></FaSave> */}

          {saving && saving.id === row.Id && saving.saving && (
            <BiRefresh style={{ marginLeft: '10px' }} />
          )}
          {saved && saved.id === row.Id && saved.saved && (
            <AiOutlineCheck style={{ marginLeft: '10px' }} />
          )}
        </>
      ),
      width: '225px',
    },

    {
      id: 17,
      name: 'Ar sumokėta',
      selector: (row) => row.IsPaid,
      cell: (row) => {
        if (row.IsPaid) {
          return <FcCheckmark onClick={() => changeVehicleStatus(row.Id)} />;
        } else {
          return (
            <IoCloseSharp
              style={{ color: 'white' }}
              onClick={() => changeVehicleStatus(row.Id)}
            />
          );
        }
      },
    },

    {
      id: 6,
      cell: (row) => (
        <AiFillEdit
          onClick={() =>
            history('/admin/managment/reservations/create', {
              state: {
                data: row,
                from: 'history',
              },
            })
          }
        />
      ),
      width: '125px',
    },
    {
      id: 7,
      cell: (row) => (
        <FaFileInvoice
          onClick={() => {
            generateInvoice(row.Id);
          }}
        />
      ),
      width: '125px',
    },
    {
      id: 8,
      cell: (row) => (
        <IoTrashOutline
          onClick={() => {
            showDialog(row.Id);
          }}
        />
      ),
      width: '125px',
    },
  ];

  function queryBuilder(vehicle, date) {
    let query = '';
    if (vehicle?.value) {
      query = `?vehicleId=${vehicle?.value}`;
    }
    // &fromDate=${date.startDate}&toDate=${date.endDate}

    if (date.startDate && date.endDate) {
      if (query === '') {
        query = `?fromDate=${date.startDate}&toDate=${date.endDate}`;
      } else {
        query = query + `&fromDate=${date.startDate}&toDate=${date.endDate}`;
      }
      return query;
    } else if (date.startDate) {
      if (query === '') {
        query = `?fromDate=${date.startDate}`;
      } else {
        query = query + `&fromDate=${date.startDate}`;
      }
      return query;
    } else if (date.endDate) {
      if (query === '') {
        query = `?toDate=${date.endDate}`;
      } else {
        query = query + `&toDate=${date.endDate}`;
      }
      return query;
    }
    return query;
  }
  async function dateChanged(date) {
    setLoading(true);
    setSelectedRange(date);
    const query = queryBuilder(selectedVehicle, date);

    const response = await getReservations(query);
    if (getReservationsResponse.status > 200) {
    } else {
      setReservations(response);
      setLoading(false);
    }
  }
  async function handleFilter(vehicle) {
    setSelectedVehicle(vehicle);
    setLoading(true);

    const query = queryBuilder(vehicle, selectedRange);
    const response = await getReservations(query);
    if (getReservationsResponse.status > 200) {
    } else {
      setReservations(response);
      setLoading(false);
    }
  }

  const conditionalRowStyles = [
    {
      when: (row) => !row?.IsPaid,
      style: {
        backgroundColor: 'Crimson',
      },
    },
  ];

  return (
    <Styled.ContentWrapper>
      <Sidebar></Sidebar>
      <div>
        <div>
          <label htmlFor="amenities">Pasirinkti automobilį</label>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '415px' }}>
              <Select
                id="vehicle"
                name="vehicle"
                // defaultOptions={defaultAmenities}
                options={vehicles}
                isMulti={false}
                value={selectedVehicle}
                // cacheOptions
                onChange={(selected) => {
                  handleFilter(selected);
                }}
              ></Select>
            </div>

            {/* <Styled.Row style={{ justifyContent: 'center' }}> */}
            <div>
              <CustomCalendarV2
                selectedDateRange={(date) => dateChanged(date)}
              ></CustomCalendarV2>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {vehicles &&
            vehicles.map((veh) => {
              return (
                <Styled.FilterBlock
                  active={veh.value === activeFilter ? true : false}
                  onClick={(filter) => {
                    setActiveFilter(veh.value);
                    handleFilter(veh);
                  }}
                >
                  {veh.label}
                </Styled.FilterBlock>
              );
            })}
        </div>
        {/* </Styled.Row> */}
        {(loading && (
          <div>
            <Loader></Loader>
          </div>
        )) || (
          <div style={{ width: '100%' }}>
            <Styled.Table>
              <DataTable
                title="Visos rezervacijos"
                columns={columns}
                data={Reservations}
                conditionalRowStyles={conditionalRowStyles}
                // defaultSortFieldId={5}

                defaultSortAsc={false}
                defaultSortFieldId={5}
                paginationPerPage={30}
                onSort={() => {
                  console.log('test1');
                }}
                // sortIcon={<SortIcon />}
                pagination
              />
            </Styled.Table>
            {/* <Button
            onClick={() => history('/admin/managment/reservations/create')}
            text="Rezervuoti"
          ></Button> */}
          </div>
        )}
      </div>
    </Styled.ContentWrapper>
  );
};
export default Reservations;
// 2sav raudonai
// be numerio.
// filtruotas pasirinkimas be numerio
// uzimtumas
// istorija
