import React from 'react';
import { ReactComponent as TickIcon } from '../../../assets/icons/tick.svg';
import Styled from './Checkbox.styles';

const Checkbox = ({
  children,
  onChange,
  name,
  checked,
  error,
  value,
  boldParagraph,
}) => {
  return (
    <Styled.Checkbox error={!!error}>
      <label>
        <input
          value={value}
          type="checkbox"
          onChange={onChange}
          name={name}
          checked={checked}
        />

        <Styled.Label boldParagraph={boldParagraph}>
          <Styled.Box>
            <TickIcon />
          </Styled.Box>

          {children}
        </Styled.Label>
      </label>
    </Styled.Checkbox>
  );
};

export default Checkbox;
