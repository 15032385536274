/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AiFillFacebook } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import { AiFillPhone } from 'react-icons/ai';
import { MdMail } from 'react-icons/md';
import './sharedStyles.css';
import { Link } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './navbar.css';
export default function Header() {
  const [mobile, setMobile] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1100) {
      setMobile(true);
    } else setMobile(false);
  }, [width]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {' '}
      {!mobile && (
        <Wrapper>
          <div
            style={{
              display: 'flex',
              height: '48px',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0,0,0,0.7)',
              paddingRight: '100px',
            }}
          >
            <span style={{ color: 'white' }}>
              {' '}
              <AiFillPhone style={{ marginRight: '12px' }}></AiFillPhone><a style={{ color: "white" }} href="tel:+37063663594">  +370 636 63594</a>
            </span>
            <span style={{ color: 'white', marginLeft: '100px' }}>
              <AiFillPhone style={{ marginRight: '12px' }}></AiFillPhone>
              <a style={{ color: "white" }} href="tel:+37063663596">  +370 636 63596</a>
            </span>
            <a
              style={{ color: 'white', marginLeft: '100px' }}
              href="mailto: info@idratai.lt"
            >
              <MdMail style={{ marginRight: '12px' }}></MdMail>
              info@idratai.lt
            </a>
          </div>
          <ItemsWrapper>
            <Items>
              <Link to="main" smooth={false}>
                <Logo>
                  <img src="/img/idRataiSmall.jpg" alt="img" />
                </Logo>
              </Link>
            </Items>
            <Items>
              <Link to="main" smooth={false}>
                <NavItem className="hover-underline-animation">
                  ID Ratai
                </NavItem>
              </Link>
              <Link to="buses" smooth={false}>
                <NavItem className="hover-underline-animation">
                  Mikroautobusai
                </NavItem>
              </Link>

              <Link to="rent-price" smooth={false}>
                <NavItem className="hover-underline-animation">
                  Nuomos Kainos
                </NavItem>
              </Link>

              <Link to="contactForm" smooth={false}>
                <NavItem className="hover-underline-animation">
                  Kontaktai
                </NavItem>
              </Link>
            </Items>
            <Items>
              <Icons>
                <a href="https://www.facebook.com/IDRatai" target="_blank">
                  <AiFillFacebook></AiFillFacebook>
                </a>

                <a href="https://www.instagram.com/mb_idratai/" target="_blank">
                  {' '}
                  <AiFillInstagram> </AiFillInstagram>
                </a>
                <a href="mailto: info@idratai.lt">
                  <MdMail></MdMail>
                </a>
              </Icons>
            </Items>
          </ItemsWrapper>
        </Wrapper>
      )}
      {mobile && (
        <header className="navbar">
          <div className="container">
            <nav>
              <div className="menu-icon" style={{ display: 'flex', justifyContent: "space-around", width: '100%', alignItems: 'center' }} >
                <HeaderItemsMobile>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    <div className="logo" style={{ marginLeft: '30px', width: '50px' }}>
                      <Logo>
                        <img src="/img/idRataiSmall.jpg" alt="img" />
                      </Logo>
                    </div>
                    <FaBars onClick={toggleMenu} style={{ marginLeft: '145px' }}></FaBars>

                  </div>

                  <span
                    style={{ color: 'black', marginTop: '5px', fontSize: '16px', marginLeft: '35px' }}
                  >
                    <AiFillPhone
                      style={{ marginRight: '8px', marginBottom: '3px' }}
                    ></AiFillPhone>{' '}
                    <a href="tel:+37063663594">+370 636 63594</a>
                    <a style={{ textDecoration: 'none !important', marginLeft: '5px' }} href="tel:+37063663596">+370 636 63596</a>
                  </span>

                </HeaderItemsMobile>
              </div>


              <div className={isOpen ? 'menu open' : 'menu'}>
                <ul style={{ flexDirection: 'column' }}>
                  <li>
                    <Link onClick={toggleMenu} to="main" smooth={true}>
                      <a>ID Ratai</a>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleMenu} to="buses" smooth={true}>
                      <a>Mikroautobusai</a>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleMenu} to="rent-price" smooth={true}>
                      <a>Nuomos Kainos</a>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={toggleMenu} to="contacts" smooth={true}>
                      <a>Kontaktai</a>
                    </Link>
                  </li>
                </ul>
              </div>
              {isOpen && <div className="backdrop" onClick={toggleMenu}></div>}
            </nav>
          </div>
          <style jsx>{`
            .navbar {
              background-color: white;
              color: #fff;
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 1001;
            }

            .container {
              max-width: 1200px;
              margin: 0 auto;
              padding: 0 20px;
            }

            nav {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 110px;
              @media (max-width: 562px) {
                height: 140px;
              }
            }

            .logo a {
              color: #fff;
              font-size: 28px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 2px;
            }

            .menu {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              background-color: #333;
              height: 35vh;
              width: 100%;
              position: absolute;
              top: 80px;
              left: 0;
              z-index: 999;
              display: none;
            }

            .menu ul {
              list-style: none;
              text-align: center;
            }

            .menu ul li {
              margin-top: 20px;
            }

            .menu ul li a {
              color: #fff;
              font-size: 24px;
              font-weight: 600;
              text-decoration: none;
              cursor: pointer;
            }

            .menu.open {
              display: flex;
            }

            .menu-icon {
              color: black;
              cursor: pointer;
              display: block;
              font-size: 24px;
              position: absolute;
              top: 28px;
              right: 20px;
            }

            .menu-icon i:hover {
              color: #ccc;
            }

            .backdrop {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 998;
            }

            @media (min-width: 1105px) {
              .menu {
                flex-direction: column;
                height: auto;
                position: static;
                background-color: transparent;
              }
              .menu ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .menu ul li {
                margin: 0 15px;
              }
              .menu-icon {
                display: none;
              }
            }
          `}</style>
        </header>
      )}
    </>
  );
}

const Wrapper = styled.div`
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1500;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Items = styled.div`
  display: flex;
  min-height: 100px;

  flex-direction: row;
  align-items: center;
  width: 50%;
  justify-content: space-around;
  svg {
    min-height: 50px;
    min-width: 50px;
    line-height: 40px;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
`;

const Logo = styled.div`
  img {
    height: 60px;
    width: 180px;
  }
  cursor: pointer;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  cursor: pointer;
  *:hover {
    color: red;
  }

`;
const HeaderItemsMobile = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-direction: column;
  @media (max-width: 560px) {
    flex-direction: column;
  }

`;