import { useCallback } from 'react';
// import useFetch from 'use-http';
// import jwtDecode from 'jwt-decode';
import SessionStorage from '../context/SessionStorage/sessionStorage';

function useToken() {
  const handleToken = useCallback(async () => {
    let content = SessionStorage.getContent();
    let token = content.token;
    let refreshToken = content.refreshToken;

    if (!token) {
    } else {
      if (token && refreshToken) {
        const expired = false;
        if (expired) {
          console.error('401 - token expired');
        }
      }
    }
    SessionStorage.setContent({
      ...content,
      ...{ token: token, refreshToken: refreshToken },
    });

    return token;
  }, []);

  return {
    handleToken,
  };
}

export default useToken;
