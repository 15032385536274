import { Navbar, Container, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { useLocalStorage } from 'react-use';
import {
  useUserDispatch,
  useUserState,
} from '../../context/UserContext/UserContext';
import Styled from './Header.styles';
const Header = () => {
  const userState = useUserState();
  let history = useNavigate();
  const location = useLocation();
  const sessionDispatch = useUserDispatch();

  return <></>;
};
export default Header;
