import DataTable from 'react-data-table-component';
import React from 'react';
import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import { useState } from 'react';
import { FcCheckmark } from 'react-icons/fc';
import { IoCloseSharp } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import { useNavigate } from 'react-router';
import Button from '../../../../shared/Button/Button';
import Loader from '../../../../shared/Loader/Loader.js';

const VehiclesList = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState();

  const { get: getVehicles, response: getVehiclesResponse } = useFetch(
    '/Vehicles/GetVehicles',
  );

  const { post: changeStatus } = useFetch('/Vehicles/ChangeVehicleStatus');

  const [Reservations, setReservations] = useState();

  async function changeVehicleStatus(id) {
    await changeStatus('', {
      Id: id,
    });

    updateState(id);
  }

  const updateState = (id) => {
    const newState = Reservations.map((obj) => {
      // 👇️ if id equals 2, update country property
      if (obj.Id === id) {
        return { ...obj, Active: !obj.Active };
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setReservations(newState);
  };

  useEffectOnce(() => {
    getVehiclesFn();
  });

  const getVehiclesFn = async () => {
    setLoading(true);
    const response = await getVehicles();
    if (getVehiclesResponse.status > 200) {
    } else {
      setReservations(response);
      setLoading(false);
    }
  };

  const columns = [
    {
      id: 1,
      name: 'Plate',
      selector: (row) => row.Plate,
      sortable: true,
      reorder: true,
    },

    {
      id: 2,
      name: 'Technikinė iki',
      selector: (row) => row.TechDate,
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row.UpdateTech,
          style: {
            backgroundColor: 'red',
          },
        },

        // You can also pass a callback to style for additional customization
      ],
    },

    {
      id: 3,
      name: 'Civilinis draudimas',
      selector: (row) => row.InsuranceDate,
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row.UpdateInsurance,
          style: {
            backgroundColor: 'red',
          },
        },

        // You can also pass a callback to style for additional customization
      ],
    },

    {
      id: 4,
      name: 'Kasko',
      selector: (row) => row.Kasko,
      sortable: true,
      reorder: true,
      conditionalCellStyles: [
        {
          when: (row) => row.UpdateKasko,
          style: {
            backgroundColor: 'red',
          },
        },

        // You can also pass a callback to style for additional customization
      ],
    },

    {
      //   cell: (row) => <FaTrash onClick={() => HandleRemove(row.Id)} />,
    },
    {
      name: 'Active',
      selector: (row) => row.Active,
      cell: (row) => {
        if (row.Active) {
          return <FcCheckmark onClick={() => changeVehicleStatus(row.Id)} />;
        } else {
          return (
            <IoCloseSharp
              style={{ color: 'red' }}
              onClick={() => changeVehicleStatus(row.Id)}
            />
          );
        }
      },
    },

    //  <FcCheckmark
    //       onClick={() =>
    //         history('/admin/managment/vehicles/create', { data: row })
    //       }
    //     />
    {
      cell: (row) => (
        <AiFillEdit
          onClick={() =>
            history('/admin/managment/vehicles/create', {
              state: { data: row },
            })
          }
        />
      ),
    },
  ];

  return (
    <Styled.ContentWrapper>
      <Sidebar></Sidebar>
      {(loading && (
        <div>
          <Loader></Loader>
        </div>
      )) || (
        <div style={{ width: '100%' }}>
          <Styled.Table>
            <DataTable
              title="Automobiliai"
              columns={columns}
              data={Reservations}
              defaultSortFieldId={1}
              paginationPerPage={30}
              // sortIcon={<SortIcon />}
              pagination
            />
          </Styled.Table>
          <Button
            onClick={() => history('/admin/managment/vehicles/create')}
            text="Pridėti naują automobilį"
          ></Button>
        </div>
      )}
    </Styled.ContentWrapper>
  );
};
export default VehiclesList;
