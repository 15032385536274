import { Provider as FetchProvider, CachePolicies } from 'use-http';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../shared/Footer/Footer';
import Header from '../shared/Header/Header';
import LoginPage from '../pages/UserPanelPage/AccountManagment/Login/LoginPage';
import { UserProvider } from '../context/UserContext/UserContext';
import RegistrationPage from '../pages/UserPanelPage/AccountManagment/Registration/RegisterPage';
import MainPage from '../pages/MainPage/MainPage';
import useToken from '../hooks/useToken';
import VehiclesList from '../pages/UserPanelPage/AccountManagment/Vehicles/VehiclesList';
import AddVehicle from '../pages/UserPanelPage/AccountManagment/Vehicles/AddVehicle';
import Reservations from '../pages/UserPanelPage/AccountManagment/Reservations/Reservations';
import ReserveVehicle from '../pages/UserPanelPage/AccountManagment/Reservations/ReserveVehicle';
import FreeTimes from '../pages/UserPanelPage/AccountManagment/FreeTimes/FreeTimes';
import Availability from '../pages/UserPanelPage/AccountManagment/AvailabilityPage/Availability';
import ManageJob from '../pages/UserPanelPage/AccountManagment/Jobs/ManageJob';
import ReservationHistory from '../pages/UserPanelPage/AccountManagment/ReservationHistory/ReservationHistory';
import Jobs from '../pages/UserPanelPage/AccountManagment/Jobs/Jobs';
import { Helmet } from 'react-helmet';
import Landing from '../../screens/Landing';
import AppliedJobs from '../pages/UserPanelPage/AccountManagment/AppliedJobs/AppliedJobs';
import ManageAppliedJob from '../pages/UserPanelPage/AccountManagment/AppliedJobs/ManageAppliedJob';
import PriceCalculator from '../pages/UserPanelPage/AccountManagment/PriceCalculator/PriceCalculator';
function App() {
  const { handleToken } = useToken();

  return (
    <FetchProvider
      url={process.env.REACT_APP_LOCAL_API_BASE}
      options={{
        cachePolicy: CachePolicies.NO_CACHE,
        interceptors: {
          request: async ({ options }) => {
            const token = await handleToken();
            options.headers.Authorization = `Bearer ${token}`;
            return options;
          },
        },
      }}
    >
      <UserProvider>
        {/* <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
            rel="stylesheet"
          />
        </Helmet> */}

        <Router>
          <Header />

          <Routes>
            <Route path="/admin" exact element={<LoginPage />}></Route>

            {/* <Route path="/register" exact>
              <RegistrationPage />
            </Route> */}

            <Route
              path="/admin/managment/vehicles"
              exact
              element={<VehiclesList />}
            ></Route>

            <Route
              path="/admin/managment/vehicles/create"
              exact
              element={<AddVehicle />}
            ></Route>

            <Route
              path="/admin/managment/reservations"
              exact
              element={<Reservations />}
            ></Route>

            <Route
              path="/admin/managment/reservations/create"
              exact
              element={<ReserveVehicle />}
            ></Route>

            <Route
              path="/admin/managment/check/reservation"
              exact
              element={<FreeTimes />}
            ></Route>

            <Route
              path="/admin/managment/check/availability"
              exact
              element={<Availability />}
            ></Route>

            <Route
              path="/admin/managment/jobs"
              exact
              element={<Jobs />}
            ></Route>

            <Route
              path="/admin/managment/jobs/applied"
              exact
              element={<AppliedJobs />}
            ></Route>

            <Route
              path="/admin/managment/jobs/create"
              exact
              element={<ManageJob />}
            ></Route>
            <Route
              path="/admin/managment/jobs/create/applied"
              exact
              element={<ManageAppliedJob />}
            ></Route>

            <Route
              path="/admin/managment/history/reservations"
              exact
              element={<ReservationHistory />}
            ></Route>

            <Route
              path="/admin/managment/calculator"
              exact
              element={<PriceCalculator />}
            ></Route>

            <Route path="/" exact element={<Landing />}></Route>

            <Route path="/main" exact element={<Landing />}></Route>

            {/* <Navigate to="/"></Navigate> */}
          </Routes>
          {/* <Footer /> */}
        </Router>
      </UserProvider>
    </FetchProvider>
  );
}

export default App;
