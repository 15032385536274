import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';
const Form = styled.div`
  margin-top: 50px;
  display: flex;
  background-color: #f2f0f0;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 15px;
  }
  width: 80%;
`;
const FieldWrapper = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: 1150px) {
    align-items: center;
    div {
      width: 90%;
    }
  }
  ${mediaDown('md')`
  align-items: center;
  width: 100%;
  `}
  flex-direction: column;
  padding-top: 25px;
  label:not(:first-child) {
    margin-top: 15px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    div {
      width: 90%;
    }
  }

  ${mediaDown('md')`
   flex-direction: column;
   align-items:center;
    width: 80%;
  `}
  label {
    display: flex;
    flex-direction: column;
  }
  label:not(:first-child) {
    margin-top: 15px;
  }
  gap: 15px;
  ${mediaDown('md')`
   gap: 0;
  `}
`;

const Error = styled.div`
  word-wrap: break-word;
  color: white;
  background-color: red;
  border-radius: 3px;
`;

const FormWrap = styled.div`
  height: calc(100vh - 210px);
  width: 100%;

  span {
    display: flex;
    justify-content: center;
    a {
      padding-left: 5px;
      text-decoration: underline;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  margin: 20px;
  outline: none;
  width: 130px;
  height: 40px;
  padding: 5px 25px;
  border: 2px solid #000;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  &:hover {
    box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
      7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  }
`;
const Test = styled.div``;

const Styled = {
  Test,
  Form,
  Error,
  FormWrap,
  Row,
  FieldWrapper,
  ButtonWrap,
  Button,
};
export default Styled;
