import React from 'react';
import Styled from './Radio.styles';

const Radio = ({ children, onChange, name, checked, value, error, style }) => {
  return (
    <Styled.Radio error={!!error} style={style}>
      <label>
        <input
          type="radio"
          onChange={() => onChange(value)}
          name={name}
          checked={checked}
        />

        <Styled.Label>
          <Styled.Box />
          {children}
        </Styled.Label>
      </label>
    </Styled.Radio>
  );
};

export default Radio;
