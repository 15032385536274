import { Formik, Form, Field, ErrorMessage } from 'formik';
import useFetch from 'use-http';
import * as Yup from 'yup';
import Styled from './LoginPage.styles';
import { useUserDispatch } from '../../../../context/UserContext/UserContext';
import { useEffectOnce } from 'react-use';
import { Navigate, useNavigate } from 'react-router';
import Button from '../../../../shared/Button/Button';
import Input from '../../../../shared/Input/Input';

const LoginPage = () => {
  const sessionDispatch = useUserDispatch();
  const history = useNavigate();
  useEffectOnce(() => {
    sessionDispatch({ type: 'RESET' });
  });

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  const { post: login, response: loginResponse } = useFetch('/Auth/SignIn');

  const onError = {
    color: 'red',
    border: '2px solid #d66',
  };

  const handleLogin = async (data, { setErrors }) => {
    const response = await login('', {
      username: data.username,
      password: data.password,
    });
    if (loginResponse.status > 200) {
      const errors = {
        username: 'invalid username or password',
      };
      setErrors(errors);
    } else {
      sessionDispatch({
        type: 'UPDATE_TOKENS',
        data: {
          token: response.Token,
          refreshToken: response.RefreshToken,
        },
      });
      sessionDispatch({
        type: 'UPDATE',
        data: {
          ...response.User,
          ...{ IsLoggedIn: true },
        },
      });
      history('/admin/managment/reservations');
    }
  };

  return (
    <Styled.FormWrap>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({ errors, touched }) => (
          <Form>
            <Styled.Form>
              <Styled.FieldWrapper>
                <label htmlFor="username">Username</label>
                <Field
                  id="username"
                  name="username"
                  style={errors.username ? onError : null}
                  component={Input}
                  placeholder="Enter username"
                />

                <ErrorMessage name="username" component={Styled.Error} />
                <label htmlFor="password">Password</label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter password"
                  component={Input}
                  style={errors.password ? onError : null}
                />
                <ErrorMessage name="password" component={Styled.Error} />
              </Styled.FieldWrapper>
              <Button text="Login" type="submit" />
            </Styled.Form>
          </Form>
        )}
      </Formik>
      <span>
        Dont have acccount yet?
        <Styled.Link onClick={() => history('/register')}>
          Register here
        </Styled.Link>
      </span>
    </Styled.FormWrap>
  );
};
export default LoginPage;
