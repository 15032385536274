import { useState } from 'react';
import useFetch from 'use-http';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import Styled from '../SharedStyle.styles';
import StyledFreeTime from './FreeTimes.styled';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Button from '../../../../shared/Button/Button';
import DataTable from 'react-data-table-component';
import Loader from '../../../../shared/Loader/Loader.js';
const FreeTimes = () => {
  const [value, onChange] = useState([new Date(), new Date()]);
  const [loading, setLoading] = useState();
  const [Reservations, setReservations] = useState();
  const { post: checkFreeReservations } = useFetch(
    '/Reservation/CheckFreeReservations',
  );

  async function searchForFreeVehicles() {
    if (value !== null) {
      setLoading(true);
      const reservations = await checkFreeReservations('', {
        dateFrom: value[0],
        dateTo: value[1],
      });
      setReservations(reservations);
      setLoading(false);
    }
  }

  const columns = [
    {
      id: 1,
      name: 'Valstybinis numeris',
      selector: (row) => row.LicensePlate,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: 'Išnuomuota iki',
      selector: (row) => row.ReservedTo,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'Išnuomuota nuo',
      selector: (row) => row.ReservedFrom,
      sortable: true,
      reorder: true,
    },

    {
      //   cell: (row) => <FaTrash onClick={() => HandleRemove(row.Id)} />,
    },
  ];

  return (
    <>
      <Styled.ContentWrapper>
        <Sidebar></Sidebar>
        <StyledFreeTime.Content>
          <StyledFreeTime.Time>
            Pasirinkti laiką
            <DateRangePicker
              onChange={onChange}
              value={value}
              locale={'lt'}
              format="yyyy-MM-dd"
            />
          </StyledFreeTime.Time>
          <Button onClick={searchForFreeVehicles} text={'Ieškoti'}></Button>
        </StyledFreeTime.Content>
        {(loading && (
          <div>
            <Loader></Loader>
          </div>
        )) || (
          <Styled.Table style={{ marginTop: '250px' }}>
            <DataTable
              title="Laisvi automobiliai"
              columns={columns}
              data={Reservations}
              defaultSortFieldId={5}
              // sortIcon={<SortIcon />}
              pagination
            />
          </Styled.Table>
        )}
      </Styled.ContentWrapper>
    </>
  );
};
export default FreeTimes;
