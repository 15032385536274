import React, { useEffect } from 'react';
import SessionStorage from '../SessionStorage/sessionStorage';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'RESET': {
      return {
        ...SessionStorage.initialState.user,
      };
    }
    case 'LOGOUT': {
      SessionStorage.setContent({ token: null, refreshToken: null });
      return {
        ...SessionStorage.initialState.user,
      };
    }
    case 'UPDATE': {
      return { ...state, ...action.data };
    }
    case 'UPDATE_TOKENS': {
      SessionStorage.setContent({
        token: action.data.token,
        refreshToken: action.data.refreshToken,
      });
      return { ...state };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider(props) {
  const [state, dispatch] = React.useReducer(
    userReducer,
    SessionStorage.getContent().user,
  );

  useEffect(() => {
    let content = SessionStorage.getContent();
    SessionStorage.setContent({
      ...content,
      ...{ user: state },
    });
  }, [state]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {props.children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }

  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }

  return context;
}

export { UserProvider, useUserState, useUserDispatch };
