import Styled from './Button.styles';

const Button = (props) => {
  return (
    <Styled.Button
      type={props.type}
      onClick={props.onClick}
      style={props.style}
    >
      {props.text}
    </Styled.Button>
  );
};
export default Button;
