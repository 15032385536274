import DataTable from 'react-data-table-component';
import React from 'react';
import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import { useState } from 'react';
import { FcCheckmark } from 'react-icons/fc';
// IoCloseSharp;
import { IoCloseSharp, IoTrashOutline } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import Styled from '../SharedStyle.styles';
import Sidebar from '../../../../shared/Sidebar/Sidebar';
import { useNavigate } from 'react-router';
import Button from '../../../../shared/Button/Button';
import Loader from '../../../../shared/Loader/Loader.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AppliedJobs = () => {
  const history = useNavigate();
  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState();
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState();

  const { get: getJobs, response: getJobsResponse } = useFetch(
    '/Vehicles/GetAppliedJobs',
  );

  const { delete: removeJob } = useFetch('/Vehicles/RemoveAppliedJob');

  const { get: vehicleOptions } = useFetch('/Vehicles/GetVehiclesOptions/true');

  const [jobs, setJobs] = useState();

  useEffectOnce(() => {
    getJobsFn();
  });

  const getJobsFn = async () => {
    setLoading(true);
    const response = await getJobs();
    if (getJobsResponse.status > 200) {
    } else {
      setJobs(response);
      setLoading(false);
    }
  };

  const showDialog = (id) => {
    confirmAlert({
      title: 'Patvirinti ištrynimą',
      message: 'Ar tikrai norite ištrinti darbą?',
      buttons: [
        {
          label: 'Taip',
          onClick: () => handleDelete(id),
        },
        {
          label: 'Ne',
        },
      ],
    });
  };

  async function handleDelete(id) {
    await removeJob(id);

    setJobs((current) =>
      current.filter((obj) => {
        return obj.Id !== id;
      }),
    );
  }

  const columns = [
    {
      id: 1,
      name: 'Valst. Nr',
      selector: (row) => row.Vehicle?.Plate,
      sortable: true,
      reorder: true,
    },

    {
      id: 2,
      name: 'Darbas',
      selector: (row) => row.Name,
      sortable: true,
      reorder: true,
      width: '700px',
    },
    {
      id: 3,
      name: 'Km',
      selector: (row) => row.Km,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: 'Data',
      selector: (row) => row.CreatedAt.substring(0, 10),
      sortable: true,
      reorder: true,
    },

    {
      // cell: (row) => <FaTrash onClick={() => HandleRemove(row.Id)} />,
    },

    //  <FcCheckmark
    //       onClick={() =>
    //         history('/admin/managment/vehicles/create', { data: row })
    //       }
    //     />
    {
      cell: (row) => (
        <AiFillEdit
          onClick={() =>
            history('/admin/managment/jobs/create/applied', {
              state: { job: row },
            })
          }
        />
      ),
    },
    {
      cell: (row) => (
        <IoTrashOutline
          onClick={() => {
            // handleDelete(row.Id);
            showDialog(row.Id);
          }}
        />
      ),
    },
  ];

  useEffectOnce(() => {
    getVehicleOptions();
  });

  const getVehicleOptions = async () => {
    const response = await vehicleOptions();
    if (response) {
      const tmp = [];
      tmp.push({ value: null, label: 'Visi automobiliai' });
      response.forEach((d) => {
        tmp.push({ value: d.Value, label: d.Label });
      });
      setVehicles(tmp);
    }
  };

  async function handleFilter(vehicle) {
    setSelectedVehicle(vehicle);
    setLoading(true);
    let response;
    if (vehicle.value != null) {
      response = await getJobs(`?vehicleId=${vehicle.value}`);
    } else {
      response = await getJobs();
    }
    // const query = queryBuilder(vehicle, selectedRange);

    if (getJobsResponse.status > 200) {
    } else {
      setJobs(response);
      setLoading(false);
    }
  }
  return (
    <Styled.ContentWrapper>
      <Sidebar></Sidebar>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex' }}>
          {vehicles &&
            vehicles.map((veh) => {
              return (
                <Styled.FilterBlock
                  active={veh.value === activeFilter ? true : false}
                  onClick={(filter) => {
                    setActiveFilter(veh.value);
                    handleFilter(veh);
                  }}
                >
                  {veh.label}
                </Styled.FilterBlock>
              );
            })}
        </div>

        {(loading && (
          <div>
            <Loader></Loader>
          </div>
        )) || (
          <div style={{ width: '100%' }}>
            <Styled.Table>
              <DataTable
                title="Atlikti darbai"
                columns={columns}
                data={jobs}
                defaultSortFieldId={1}
                paginationPerPage={30}
                // sortIcon={<SortIcon />}
                pagination
              />
            </Styled.Table>
            <Button
              onClick={() => history('/admin/managment/jobs/create/applied')}
              text="Pridėti naują darbą"
            ></Button>
          </div>
        )}
      </div>
    </Styled.ContentWrapper>
  );
};
export default AppliedJobs;
